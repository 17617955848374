package tta.destinigo.talktoastro.feature_home.presentation.ecom_list

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyHorizontalGrid
import androidx.compose.foundation.lazy.grid.items
import androidx.compose.foundation.lazy.items
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import tta.destinigo.talktoastro.feature_home.data.response.ecom_product.TopProductList
import tta.destinigo.talktoastro.feature_home.presentation.model.HomeTopProductCard
import tta.destinigo.talktoastro.shared.persentation.HeadLineTextWithMoreButton
import tta.destinigo.talktoastro.shared.theme.Scale

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun HomeTopProductSegment(
    modifier: Modifier = Modifier,
    topProductCard: HomeTopProductCard,
    onClickSeeAll: () -> Unit,
    onClickProduct: (productUrl: String) -> Unit
) {
    val windowWidthSizeClass = calculateWindowSizeClass().widthSizeClass

    Box(
        modifier = Modifier.fillMaxWidth().wrapContentHeight(),
        contentAlignment = Alignment.Center
    ) {
        Column(
            modifier = modifier.padding(horizontal = Scale.HORIZONTAL_PADDING)
        ) {

            if (topProductCard.trendingProduct.isEmpty().not()){
                // Section 1: Trending Products
                HeadLineTextWithMoreButton(
                    modifier = Modifier.fillMaxWidth(),
                    title = "Spiritual Accessories (Should Buy)",
                    onClickMoreButton = { onClickSeeAll() }
                )

                LazyRow(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.spacedBy(8.dp)
                ) {
                    items(topProductCard.trendingProduct) { product ->
                        ProductCardTrending(
                            shopItemImage = product.image.orEmpty(),
                            rating = product.rating,
                            discountText = product.discountPercent,
                            productName = product.name,
                            discountedPrice = product.discountedPrice,
                            actualPrice = product.regularPrice,
                            onAddToCartClick = { onClickProduct(product.link) },
                            windowWidthSizeClass = windowWidthSizeClass,
                            modifier = Modifier
                        )
                    }
                }

                Spacer(modifier = Modifier.height(6.dp))
            }


            if (topProductCard.products.isEmpty().not()) {
                // Section 2: Top Rated Products
                HeadLineTextWithMoreButton(
                    modifier = Modifier.fillMaxWidth(),
                    title = "Rooted in Traditions",
                    onClickMoreButton = { onClickSeeAll() }
                )

                val contentModifier = remember(windowWidthSizeClass) {
                    when (windowWidthSizeClass) {
                        WindowWidthSizeClass.Expanded -> Modifier.height(360.dp)
                        WindowWidthSizeClass.Medium -> Modifier.height(320.dp)
                        else -> Modifier.height(280.dp).fillMaxWidth()
                    }
                }

                val mainContent: @Composable () -> Unit = {
                    TopRatedProduct(
                        modifier = Modifier.fillMaxHeight(),
                        product = topProductCard.highlightedProduct,
                        windowWidthSizeClass = windowWidthSizeClass,
                        onProductClick = { onClickProduct(topProductCard.highlightedProduct?.link.orEmpty()) }
                    )

                    Spacer(
                        modifier = if (windowWidthSizeClass == WindowWidthSizeClass.Compact)
                            Modifier.height(12.dp)
                        else
                            Modifier.width(8.dp)
                    )

                    LazyHorizontalGrid(
                        rows = GridCells.Fixed(2),
                        modifier = contentModifier.then(
                            if (windowWidthSizeClass != WindowWidthSizeClass.Compact) Modifier.weight(1f) else Modifier
                        ),
                        verticalArrangement = Arrangement.spacedBy(8.dp),
                        horizontalArrangement = Arrangement.spacedBy(8.dp)
                    ) {
                        items(topProductCard.products) { product ->
                            ProductCardSmall(
                                shopItemImage = product.image.orEmpty(),
                                rating = product.rating,
                                discountText = product.discountPercent,
                                productName = product.name,
                                discountedPrice = product.discountedPrice,
                                actualPrice = product.regularPrice,
                                onAddToCartClick = { onClickProduct(product.link) },
                                windowWidthSizeClass = windowWidthSizeClass,
                                modifier = Modifier
                            )
                        }
                    }
                }

                if (windowWidthSizeClass == WindowWidthSizeClass.Compact) {
                    Column(
                        verticalArrangement = Arrangement.Center,
                        horizontalAlignment = Alignment.CenterHorizontally,
                        modifier = Modifier.fillMaxWidth().wrapContentHeight()
                    ) {
                        mainContent()
                    }
                } else {
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.Start,
                        modifier = Modifier.fillMaxWidth().height(360.dp)
                    ) {
                        mainContent()
                    }
                }

            }

        }
    }
}

@Composable
fun TopRatedProduct(
    modifier: Modifier = Modifier,
    product: TopProductList?,
    windowWidthSizeClass: WindowWidthSizeClass,
    onProductClick: () -> Unit = {}
) {
    product?.let {
        Box(modifier = modifier) {
            ProductCardBig(
                shopItemImage = it.image.orEmpty(),
                rating = it.rating,
                discountText = it.discountPercent,
                productName = it.name,
                discountedPrice = it.discountedPrice,
                actualPrice = it.regularPrice,
                onAddToCartClick = { onProductClick() },
                windowWidthSizeClass = windowWidthSizeClass
            )
        }
    }
}



//
//@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
//@Composable
//fun HomeTopProductSegmant(
//    modifier: Modifier = Modifier,
//    topProductCard: HomeTopProductCard,
//    onClickSeeAll: () -> Unit,
//    onClickProduct: (productUrl: String) -> Unit
//) {
//
//    val windowWidthSizeClass = calculateWindowSizeClass().widthSizeClass
//
//    Box(
//        modifier = Modifier.fillMaxWidth().wrapContentHeight(),
//        contentAlignment = Alignment.Center
//    ) {
//        Column(
//            modifier = modifier
//                .padding(horizontal = Scale.HORIZONTAL_PADDING)
//
//        ) {
//            HeadLineTextWithMoreButton(
//                modifier = Modifier.fillMaxWidth(),
//                title = "Spiritual Accessories (Should Buy)",
//                onClickMoreButton = {
//                    onClickSeeAll.invoke()
//                }
//            )
//
//            LazyRow(
//                modifier = Modifier
//                    .fillMaxWidth().fillMaxHeight(),
//                horizontalArrangement = Arrangement.spacedBy(8.dp)
//            ) {
//
//                items(items = topProductCard.trendingProduct) { product ->
//                    ProductCardTrending(
//                        modifier = Modifier,
//                        shopItemImage = product.image ?: "",
//                        rating = product.rating,
//                        discountText = product.discountPercent,
//                        productName = product.name,
//                        discountedPrice = product.discountedPrice,
//                        actualPrice = product.regularPrice,
//                        onAddToCartClick = {
//                            onClickProduct.invoke(product.link)
//                        },
//                        windowWidthSizeClass = windowWidthSizeClass
//                    )
//                }
//            }
//
//            Spacer(modifier = Modifier.height(6.dp))
//            HeadLineTextWithMoreButton(
//                modifier = Modifier.fillMaxWidth(),
//                title = "Rooted in Traditions",
//                onClickMoreButton = {
//                    onClickSeeAll.invoke()
//                }
//            )
//
//            val mainContent: @Composable () -> Unit = {
//
//                val withModifier = when (windowWidthSizeClass) {
//                    WindowWidthSizeClass.Expanded -> modifier.height(360.dp).weight(1f)
//                    WindowWidthSizeClass.Medium -> modifier.height(320.dp).weight(1f)
//                    else -> modifier.height(280.dp).fillMaxWidth()
//                }
//
//                TopRatedProduct(
//                    modifier = Modifier.fillMaxHeight(),
//                    product = topProductCard.highlightedProduct,
//                    windowWidthSizeClass,
//                    onProductClick = {
//                        onClickProduct.invoke(topProductCard.highlightedProduct?.link ?: "")
//                    }
//                )
//
//                val spaceModifier = if (windowWidthSizeClass == WindowWidthSizeClass.Compact) Modifier.height(12.dp) else Modifier.width(8.dp)
//                Spacer(modifier = spaceModifier)
//
//                LazyHorizontalGrid(
//                    rows = GridCells.Fixed(2),
//                    modifier = withModifier,
//                    verticalArrangement = Arrangement.spacedBy(8.dp), // Spacing between rows
//                    horizontalArrangement = Arrangement.spacedBy(8.dp), //
//                ) {
//
//                    items(topProductCard.products) { product ->
//                        ProductCardSmall(
//                            modifier = Modifier,
//                            shopItemImage = product.image ?: "",
//                            rating = product.rating,
//                            discountText = product.discountPercent,
//                            productName = product.name,
//                            discountedPrice = product.discountedPrice,
//                            actualPrice = product.regularPrice,
//                            onAddToCartClick = {
//                                onClickProduct.invoke(product.link)
//                            },
//                            windowWidthSizeClass = windowWidthSizeClass
//                        )
//                    }
//                }
//            }
//
//            if (windowWidthSizeClass == WindowWidthSizeClass.Compact) {
//                Column(
//                    verticalArrangement = Arrangement.Center,
//                    horizontalAlignment = Alignment.CenterHorizontally,
//                    modifier = Modifier.fillMaxWidth().wrapContentHeight()
//                ) {
//                    mainContent.invoke()
//                }
//            } else {
//
//                Row(
//                    verticalAlignment = Alignment.CenterVertically,
//                    horizontalArrangement = Arrangement.Start,
//                    modifier = Modifier.fillMaxWidth().height(360.dp)
//                ) {
//                    mainContent.invoke()
//                }
//            }
//
//        }
//    }
//}
//
//@Composable
//fun TopRatedProduct(
//    modifier: Modifier = Modifier,
//    product: TopProductList?,
//    windowWidthSizeClass: WindowWidthSizeClass,
//    onProductClick: () -> Unit = {}
//
//) {
//
//    Box(
//        modifier = modifier
//    ) {
//        product?.let {
//            ProductCardBig(
//                shopItemImage = it.image ?: "",
//                rating = it.rating,
//                discountText = it.discountPercent,
//                productName = it.name,
//                discountedPrice = it.discountedPrice,
//                actualPrice = it.regularPrice,
//                onAddToCartClick = {
//                    onProductClick.invoke()
//                },
//                windowWidthSizeClass = windowWidthSizeClass
//            )
//        }
//    }
//
//}