package tta.destinigo.talktoastro.feature_home.data.response.shorts

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class InstaList(
    @SerialName("id") var id: Int? = null,
    @SerialName("expert_id") var expertId: String? = null,
    @SerialName("expert_name") var expertName: String? = null,
    @SerialName("profile_image") var profileImage: String? = null,
    @SerialName("instagram_script") var embedCode: String? = null,
    @SerialName("thumbnail") var thumbnail: String? = null,
    @SerialName("reel_order") var reelOrder: Int? = null,
    @SerialName("status") var status: String? = null,
    @SerialName("created_at") var createdAt: String? = null,
    @SerialName("updated_at") var updatedAt: String? = null
)
