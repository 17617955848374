package tta.destinigo.talktoastro.feature_home.di

import org.koin.dsl.module
import tta.destinigo.talktoastro.feature_home.data.api.HomeApiService
import tta.destinigo.talktoastro.feature_home.data.api.WalletBalanceApi
import tta.destinigo.talktoastro.feature_home.domain.HomePageRepository
import tta.destinigo.talktoastro.feature_home.presentation.home_tab.HomeScreenTabComponent

val homeCommonModule = module {

    single { WalletBalanceApi() }
    single { HomeApiService() }
    scope<HomeScreenTabComponent> {
        scoped { HomePageRepository(get(),get()) }
    }

//    factory { HomeApiService() }
//    factory { WalletBalanceApi() }
}