package tta.destinigo.talktoastro.feature_home.data.response.shorts

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Shorts(
    @SerialName("title")  val title:String?,
    @SerialName("videoId")  val videoId:String?,
    @SerialName("thumbnail")  val thumbnail:String?,
    @SerialName("expertName")  val expertName:String?,
    @SerialName("expertImage")  val expertImage:String?
)

