package tta.destinigo.talktoastro.feature_home.data.response.ecom_product

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class TopProductList(
    @SerialName("discount_percent") val discountPercent: String, // 0
    @SerialName("discounted_price") val discountedPrice: String, // 0
    @SerialName("image") val image: String?, // https://prod.winyway.com/winyway.shop/wp-content/uploads/2025/01/Amethyst-heart-pendant_1.jpg
    @SerialName("link") val link: String, // https://prod.winyway.com/winyway.shop/product/gomti-prosperity-ring/
    @SerialName("name") val name: String, // Gomti Prosperity Ring
    @SerialName("rating") val rating: String, // 0.00
    @SerialName("regular_price") val regularPrice: String // 0
)