package tta.destinigo.talktoastro.feature_home.presentation.home_tab.home_screen_widget

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ConnectWithoutContact
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.feature_call_consultation.persentation.call_starter.CallHostPage
import tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_duration_dailog.ChatConsultationStarter
import tta.destinigo.talktoastro.shared.data.RequestCategory
import tta.destinigo.talktoastro.feature_home.presentation.home_tab.HomeScreenTabComponent
import tta.destinigo.talktoastro.feature_home.presentation.home_tab.TopConsultantData
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.ic_filled_star
import tta.destinigo.talktoastro.shared.data.CommonExpertInfo
import tta.destinigo.talktoastro.shared.data.RechargeData
import tta.destinigo.talktoastro.shared.data.RequestConsultant
import tta.destinigo.talktoastro.shared.persentation.HeadLineTextWithMoreButton
import tta.destinigo.talktoastro.shared.theme.Scale
import tta.destinigo.talktoastro.shared.theme.Scale.CORNER_SMALL
import tta.destinigo.talktoastro.shared.theme.star_rating_color
import kotlin.math.round


@Composable
fun ConsultantListItemUI(
    component: TopConsultantData?,
    onItemClick: (RequestConsultant) -> Unit,
    onLoginClick: () -> Unit,
    onChatConsultation: (RequestConsultant, String, String) -> Unit,
    onCallConsultation: (RequestConsultant) -> Unit,
    onRechargeRequired: (RechargeData?) -> Unit,
    onVoIPClick: (String, CommonExpertInfo) -> Unit,
    onNavigateCallWaitingPage: (CommonExpertInfo, ()->Unit) -> Unit,
    onLowBalance: () -> Unit,
    onSeeAllClick: (RequestCategory) -> Unit,
    modifier: Modifier,
    context: HomeScreenTabComponent

) {
    val categoryData by context.topCategory.collectAsState()
    var isChatClickSelected by remember { mutableStateOf<RequestConsultant?>(null) }
    var isCallClickSelected by remember { mutableStateOf<RequestConsultant?>(null) }

    if (component?.consultants.isNullOrEmpty()) return

    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {
        Column(
            modifier = modifier
                .padding(horizontal = Scale.HORIZONTAL_PADDING)

        ) {
            HeadLineTextWithMoreButton(
                modifier = Modifier.fillMaxWidth(),
                title = "Top Consultants",
                onClickMoreButton = {
                    println("Top Consultants ${categoryData?.name}")
                    if(categoryData?.name.equals("Astrologer", true)){
                        categoryData?.let { onSeeAllClick.invoke(it) }
                    }
                }
            )
            LazyRow(
                modifier = Modifier
                    .fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.spacedBy(10.dp)
            ) {
                component?.consultants?.size?.let {
                    items(it) { consultantIde ->
                        val consultant = component.consultants[consultantIde]
                        ConsultantListItem(
                            consultant = consultant,
                            onItemClick = onItemClick,
                            onCallClick = { callConsultant ->
                                isCallClickSelected = callConsultant
                            },
                            onChatClick = { chatConsultant ->
                                isChatClickSelected = chatConsultant
                            },
                            isFreeCallApplicable = component.isFreeApplicable
                        )
                    }
                }
            }
        }
    }
    if (isCallClickSelected != null) {
        CallHostPage(
            callDetails = isCallClickSelected!!,
            onLoginClicked = {
                onLoginClick.invoke()
            },
            onDismiss = {
                isCallClickSelected = null
            },
            onStartCallConsultation = {
                onCallConsultation.invoke(isCallClickSelected!!)
                                      },
            onNavigateRechargeCheckOut = { rechargeData ->
                onRechargeRequired.invoke(rechargeData)
            },
            context = context,
            onVoIpCallStart = { duration, expertInfo -> onVoIPClick.invoke(duration, expertInfo) },
            onNavigateCallWaitingPage = {  CommonExpertInfo,onClose ->
                onNavigateCallWaitingPage.invoke(CommonExpertInfo,onClose)
            }
        )
    }

    if (isChatClickSelected != null) {
        ChatConsultationStarter(
            chatDetails = isChatClickSelected!!,
            onLoginClicked = {
                onLoginClick.invoke()
            },
            onDismiss = {
                isChatClickSelected = null
            },
            onStartChatConsultation = { duration, category, chatType ->
                onChatConsultation.invoke(
                    isChatClickSelected!!,
                    duration,
                    category
                )
            },
            context = context,
            onRechargeAgain = { rechargeData ->
                onRechargeRequired.invoke(rechargeData)
            },
            lowBalanceRecharge = {
                onLowBalance.invoke()
            }
        )
    }
}


@Composable
fun ConsultantListItem(
    consultant: RequestConsultant,
    onItemClick: (RequestConsultant) -> Unit,
    onCallClick: (RequestConsultant) -> Unit,
    onChatClick: (RequestConsultant) -> Unit,
    isFreeCallApplicable: Boolean
) {
    Column(
        modifier = Modifier
            .width(160.dp)
            .wrapContentHeight()
    ) {
        Surface(
            shape = RoundedCornerShape(CORNER_SMALL),
            shadowElevation = Scale.ELEVATION_SMALL
        ) {
            Column(
                modifier = Modifier
                    .clickable { onItemClick(consultant) }
                    .height(210.dp),
                verticalArrangement = Arrangement.Top,
                horizontalAlignment = Alignment.CenterHorizontally // Center the content horizontally
            ) {

                RatingRow(consultant.consultant_rating!!,consultant, modifier = Modifier.fillMaxWidth())

                Spacer(modifier = Modifier.height(8.dp))

                Box(modifier = Modifier.fillMaxWidth(),
                    contentAlignment = Alignment.Center
                ) {
                    ExpertProfileIcon(
                        profileImageUrl = consultant.profile_image, modifier = Modifier.size(86.dp),
                        data = consultant
                    )

                    Text(
                        text = "${consultant.year_of_experience}+ Years",
                        fontSize = 10.sp,
                        fontWeight = FontWeight.SemiBold,
                        textAlign = TextAlign.Center,
                        modifier = Modifier.wrapContentWidth().background(
                            color = Color.White,
                            shape = RoundedCornerShape(8.dp),
                        ).padding(horizontal = 6.dp, vertical = 2.dp).align(Alignment.BottomCenter)
                    )
                }

                Spacer(modifier = Modifier.height(4.dp))

                Text(
                    text = consultant.full_name ?: "",
                    maxLines = 1,
                    style = MaterialTheme.typography.titleSmall.copy(
                        fontWeight = FontWeight.Bold
                    ),
                    modifier = Modifier.fillMaxWidth().padding(horizontal = 10.dp),
                    textAlign = TextAlign.Center
                )

                Text(
                    text = consultant.skills ?: "",
                    maxLines = 2,
                    minLines = 2,
                    modifier = Modifier.fillMaxWidth().padding(horizontal = 10.dp),
                    style = MaterialTheme.typography.labelSmall,
                    color = Color.Gray,
                    overflow = TextOverflow.Ellipsis,
                    textAlign = TextAlign.Center
                )

                Spacer(modifier = Modifier.height(5.dp))

                ExpertPriceRow(
                    isFreeCallApplicable = isFreeCallApplicable,
                    callPerMinutePrice = consultant.callPerMinutePrice.toString(),
                    expertPrice = consultant.expert_price ?: 0,
                    winywayPrice = consultant.winyway_price ?: 0,
                    flashPrice = consultant.flashPerMinutePrice,
                    horizontalArrangement = Arrangement.Center
                )
            }
        }

        ActionButtons(
            consultant,
            onCallClick = { onCallClick.invoke(consultant) },
            onChatClick = { onChatClick.invoke(consultant) }
        )
    }
}



@Composable
fun RatingRow(
    rating: Double,
    consultant: RequestConsultant,
    modifier: Modifier
) {
    Row(
        modifier = modifier,
        horizontalArrangement = Arrangement.End,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Icon(
            painter = painterResource(Res.drawable.ic_filled_star),
            contentDescription = null,
            tint = star_rating_color,
            modifier = Modifier.size(14.dp)
        )

        ConsultantRating(rating)

        if (!consultant.isAnyUniqueExpertApplicable().isNullOrBlank()){
            HighlightingTag(expert = consultant)
        }

    }
}

@Composable
fun ActionButtons(
    consultant: RequestConsultant,
    onCallClick: () -> Unit,
    onChatClick: () -> Unit
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(50.dp).padding(top = 4.dp),
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        // Call Button
        CallConsultationButton(
            modifier = Modifier.weight(1f).height(36.dp),
            chatStatus = consultant.chatButtonStatus,
            callStatus = consultant.callStatus,
            onClick = {
                onCallClick.invoke()
            },
            isTextVisible = false
        )

        Spacer(modifier = Modifier.weight(0.1f))

        // Chat Button
        ChatConsultationButton(
            modifier = Modifier
                .weight(1f)
                .height(36.dp),
            chatStatus = consultant.chatButtonStatus,
            callStatus = consultant.callStatus,
            onClick = {
                onChatClick.invoke()
            },
            isTextVisible = false

        )
    }
}


@Composable
fun ConsultantRating(rating: Double?) {
    val roundedRating = rating?.coerceIn(0.0, 5.0)?.let { round(it * 10) / 10 } ?: 5.0
    val ratingText = "$roundedRating"
    Text(
        text = ratingText,
        fontSize = 12.sp,
        style = TextStyle(fontWeight = FontWeight.Bold),
        textAlign = TextAlign.Center,
        color = Color.Black,
        modifier = Modifier.padding(horizontal = 4.dp)
    )
}

