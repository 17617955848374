package tta.destinigo.talktoastro.feature_home.presentation.home_tab.footer

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.AssuredWorkload
import androidx.compose.material.icons.filled.Verified
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.input.pointer.positionChanged
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.feature_home.presentation.home_tab.HomePageEvent
import tta.destinigo.talktoastro.feature_home.presentation.home_tab.HomeScreenTabComponent
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.facebook1
import tta.destinigo.talktoastro.resources.facebook2
import tta.destinigo.talktoastro.resources.ic_whatsapp
import tta.destinigo.talktoastro.resources.instagram1
import tta.destinigo.talktoastro.resources.instagram2
import tta.destinigo.talktoastro.resources.linkedin1
import tta.destinigo.talktoastro.resources.linkedin2
import tta.destinigo.talktoastro.resources.youtube1
import tta.destinigo.talktoastro.resources.youtube2
import tta.destinigo.talktoastro.shared.local.openPlayStore
import tta.destinigo.talktoastro.shared.local.openWhatsApp
import tta.destinigo.talktoastro.shared.theme.Scale
import tta.destinigo.talktoastro.shared.theme.Scale.GLOBAL_ICON_SIZE
import tta.destinigo.talktoastro.shared.theme.Scale.GLOBAL_ICON_SIZE_SMALL
import tta.destinigo.talktoastro.shared.theme.winyway_facebook_page_url
import tta.destinigo.talktoastro.shared.theme.winyway_instagram_page_url
import tta.destinigo.talktoastro.shared.theme.winyway_linkedin_page_url
import tta.destinigo.talktoastro.shared.theme.winyway_youtube_page_url


@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun Footer(
    component: HomeScreenTabComponent
) {

    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val isMobileScreen = windowSizeClass == WindowWidthSizeClass.Compact

    Column(
        modifier = Modifier
            .fillMaxWidth().wrapContentHeight()
            .background(Color(0xFF212121)) // To ensure the background image is visible
    ) {

        val content: @Composable () -> Unit = {

            val textMaxWidth = when(windowSizeClass){
                WindowWidthSizeClass.Expanded -> Modifier.width(260.dp)
                WindowWidthSizeClass.Medium -> Modifier.width(180.dp)
                else -> Modifier.fillMaxWidth()
            }

            Column(
                modifier = textMaxWidth.wrapContentHeight(),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.Start
            ) {

                Text(
                    text = "About Us",
                    color = Color.White.copy(alpha = 0.5f),
                    fontWeight = FontWeight.Bold,
                    fontSize = 14.sp,
                )
                // Paragraph below the logo

                Text(
                    text = "Unlock your future with a personalized astrology consultation. Talk to astrologer on relationships," +
                            "career, and life path through birth chart analysis. Book now for clarity and cosmic guidance.",
                    color = Color.White,
                    fontSize = 13.sp,
                    modifier = Modifier.padding(top = 6.dp),
                    textAlign = TextAlign.Start
                )

            }


            LinkBox(
                title = "Quick Links",
                links = listOf(
                    "Terms and Conditions",
                    "Privacy Policy",
                    "Contact Us",
                    "Astrology Courses",
                    "Articles"
                ),
                navigateTo = { link ->
                    when (link) {
                        "Terms and Conditions" -> {
                            component.event(HomePageEvent.OnClickTermsAndConditions)
                        }

                        "Privacy Policy" -> {
                            component.event(HomePageEvent.OnClickPrivacyPolicy)
                        }

                        "Contact Us" -> {
                            // Navigate to Contact Us page
                        }

                        "Astrology Courses" -> {
                            component.event(HomePageEvent.onLandingPageClick)
                        }

                        "Articles" -> {
                            component.event(HomePageEvent.OnArticleClick)
                        }
                    }
                },
                imageItems = listOf(),
                onImageClick = { _, _, _ -> }
            )

            LinkBox(
                title = "Support",
                links = listOf("support@talktoastro.com"),
                navigateTo = { link -> /* Handle navigation */ },
                whatsAppSupport = true,
                imageItems = listOf(
                    ImageItem(
                        defaultPainter = painterResource(Res.drawable.facebook1),
                        hoverPainter = painterResource(Res.drawable.facebook2),
                        identifier = "facebook"
                    ),
                    ImageItem(
                        defaultPainter = painterResource(Res.drawable.instagram1),
                        hoverPainter = painterResource(Res.drawable.instagram2),
                        identifier = "instagram"
                    ),
                    ImageItem(
                        defaultPainter = painterResource(Res.drawable.linkedin1),
                        hoverPainter = painterResource(Res.drawable.linkedin2),
                        identifier = "linkedin"
                    ),
                    ImageItem(
                        defaultPainter = painterResource(Res.drawable.youtube1),
                        hoverPainter = painterResource(Res.drawable.youtube2),
                        identifier = "youtube"
                    )
                ),
                onImageClick = { defaultPainter, hoverPainter, identifier ->
                    when (identifier) {
                        "facebook" -> {
                            openPlayStore(winyway_facebook_page_url)
                        }

                        "instagram" -> {
                            openPlayStore(winyway_instagram_page_url)
                        }

                        "linkedin" -> {
                            openPlayStore(winyway_linkedin_page_url)
                        }

                        "youtube" -> {
                            openPlayStore(winyway_youtube_page_url)
                        }
                    }
                }
            )

            Column(
                horizontalAlignment = Alignment.Start,
                verticalArrangement = Arrangement.Top,
                modifier = Modifier.wrapContentHeight()
            ) {

                Text(
                    text = "More",
                    color = Color.White.copy(alpha = 0.5f),
                    fontWeight = FontWeight.Bold,
                    fontSize = 14.sp,
                )

                Spacer(modifier = Modifier.height(6.dp))

                IconWithText(
                    modifier = Modifier,
                    text = "Verified Expert",
                    icon = Icons.Default.Verified
                )
                Spacer(modifier = Modifier.height(2.dp))
//                Image(
//                    painter = painterResource(Res.drawable.verify_expert),
//                    contentDescription = null,
//                    contentScale = ContentScale.FillBounds
//                )
                IconWithText(
                    modifier = Modifier,
                    text = "Secure Payment",
                    icon = Icons.Default.AssuredWorkload
                )

//                Image(
//                    painter = painterResource(Res.drawable.secure_payment),
//                    contentDescription = null,
//                    contentScale = ContentScale.FillBounds
//                )
            }
        }

        // Links section for wide screens
        if (isMobileScreen.not()) {
            Row(
                modifier = Modifier.fillMaxWidth().padding(24.dp),
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                content.invoke()
            }
        } else {
            // Use a LazyVerticalGrid with 2 columns
            Column (
                modifier = Modifier
                    .fillMaxWidth().padding(18.dp),
                verticalArrangement = Arrangement.spacedBy(6.dp),
            ) {
                content.invoke()
            }
        }

        Spacer(modifier = Modifier.weight(0.1f))
        // Bottom details
        CopyrightFooter(modifier = Modifier.fillMaxWidth())

    }
}

@Composable
fun CopyrightFooter(modifier: Modifier = Modifier) {
    Box(
        modifier = modifier
            .background(Color.Black)
            .padding(9.dp),
    ) {

        Text(
            modifier = Modifier.align(Alignment.Center).fillMaxWidth(),
            text = "© 2024 Talk to Astro. All rights reserved.",
            color = Color.White,
            fontSize = 12.sp,
            textAlign = TextAlign.Center
        )
    }
}

@Composable
fun IconWithText(
    modifier: Modifier = Modifier,
    text:String,
    icon:ImageVector
) {

    Row(
        modifier = modifier
    ) {

        Icon(
            imageVector = icon,
            modifier = Modifier.size(GLOBAL_ICON_SIZE_SMALL),
            tint = Color.White,
            contentDescription = "icon"
        )

        Spacer(modifier = Modifier.width(4.dp))

        Text(
            text = text,
            color = Color.White,
            fontSize = 14.sp,
        )
    }

}


@Composable
fun LinkBox(
    title: String,
    links: List<String>,
    navigateTo: (String) -> Unit,
    imageItems: List<ImageItem> = emptyList(),
    onImageClick: (Painter, Painter, String) -> Unit = { _, _, _ -> },
    whatsAppSupport: Boolean = false

) {
    Column {
        Text(
            text = title,
            color = Color.White.copy(alpha = 0.5f),
            fontWeight = FontWeight.Bold,
            fontSize = 14.sp,
            modifier = Modifier.padding(bottom = 4.dp)
        )

        links.forEach { link ->
            Text(
                text = link,
                color = Color.White,
                fontSize = 13.sp,
                modifier = Modifier
                    .clickable { navigateTo(link) }
            )
            Spacer(modifier = Modifier.height(2.dp))
        }

        if (whatsAppSupport) {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start,
                modifier = Modifier.clickable {
                    openWhatsApp("8860095202")
                }
            ) {
                Image(
                    painter = painterResource(Res.drawable.ic_whatsapp),
                    contentDescription = "Whatsapp Icon",
                    modifier = Modifier.size(GLOBAL_ICON_SIZE),
                )
                Text(
                    text = "91-8860095202",
                    fontSize = 15.sp,
                    color = Color.White.copy(alpha = 0.7f),
                    modifier = Modifier.padding(start = 8.dp)
                )
            }
        }

        Spacer(modifier = Modifier.height(8.dp))
        if (imageItems.isNotEmpty()) {
            LazyRow(
                horizontalArrangement = Arrangement.spacedBy(8.dp)
            ) {
                items(imageItems.size) { index ->
                    val imageItem = imageItems[index]
                    var isHovered by remember { mutableStateOf(false) }

                    val painterToDisplay =
                        if (isHovered) imageItem.hoverPainter else imageItem.defaultPainter

                    Image(
                        painter = painterToDisplay,
                        contentDescription = null, // Provide content description if needed
                        modifier = Modifier
                            .size(Scale.GLOBAL_ICON_SIZE_MAX) // Adjust size as needed
                            .detectHover { isHovered = it }
                            .clickable {
                                onImageClick(
                                    imageItem.defaultPainter,
                                    imageItem.hoverPainter,
                                    imageItem.identifier
                                )
                            }
                    )
                }
            }
        }
    }
}


fun Modifier.detectHover(onHoverChanged: (Boolean) -> Unit): Modifier {
    return this.pointerInput(Unit) {
        awaitPointerEventScope {
            var isHovering = false
            while (true) {
                val event = awaitPointerEvent()
                val isCurrentlyHovering =
                    event.changes.any { it.positionChanged() && it.isConsumed }
                if (isCurrentlyHovering != isHovering) {
                    isHovering = isCurrentlyHovering
                    onHoverChanged(isHovering)
                }
            }
        }
    }
}


//@Composable
//fun AppLinkBox(
//    title: String,
//    links: List<Pair<String, DrawableResource>>,
//    navigateTo: (String) -> Unit
//) {
//    Column(
//        modifier = Modifier.padding(vertical = 8.dp)
//    ) {
//        Text(
//            text = title,
//            color = Color.White,
//            fontWeight = FontWeight.Bold,
//            fontSize = 16.sp,
//        )
//        links.forEach { (link, imageRes) ->
//            Column(
//                modifier = Modifier
//                    .clickable {
//                        if (link == "Play Store") {
//                            navigateTo(link)
//                        }
//                    }
//                    .padding(vertical = 5.dp)
//                    .hoverable()
//            ) {
//                Text(
//                    text = link,
//                    color = Color.White.copy(alpha = 0.7f),
//                    fontSize = 15.sp,
//                )
//                Spacer(modifier = Modifier.height(2.dp))
//                Image(
//                    painter = painterResource(imageRes),
//                    contentDescription = null,
//                    modifier = Modifier.height(50.dp).width(120.dp),
//                    contentScale = ContentScale.FillBounds
//                )
//
//                if (link == "App Store") {
//                    // Show Coming Soon message
//                    Text(
//                        text = "Coming Soon",
//                        color = Color.White.copy(alpha = 0.5f),
//                        fontSize = 12.sp,
//                        modifier = Modifier.padding(top = 2.dp)
//                    )
//                }
//            }
//        }
//    }
//}

//
//@Composable
//fun Modifier.hoverable(): Modifier {
//    val interactionSource = remember { MutableInteractionSource() }
//    val isHovered by interactionSource.collectIsHoveredAsState()
//
//    return this.then(
//        if (isHovered) {
//            Modifier.background(Color.White.copy(alpha = 0.1f))
//        } else {
//            Modifier
//        }
//    )
//}


