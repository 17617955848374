package tta.destinigo.talktoastro.feature_home.domain

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import tta.destinigo.talktoastro.feature_home.data.api.HomeApiService
import tta.destinigo.talktoastro.feature_home.data.api.WalletBalanceApi
import tta.destinigo.talktoastro.feature_home.data.request.PostRequest
import tta.destinigo.talktoastro.shared.data.RequestCategory
import tta.destinigo.talktoastro.feature_home.data.request.RequestSlider
import tta.destinigo.talktoastro.feature_home.data.request.RequestUpComingLiveSessions
import tta.destinigo.talktoastro.feature_home.data.response.ecom_product.TopProductList
import tta.destinigo.talktoastro.feature_home.data.response.post.PostDataNew
import tta.destinigo.talktoastro.feature_home.data.response.shorts.InstaList
import tta.destinigo.talktoastro.feature_home.presentation.home_tab.TopConsultantData
import tta.destinigo.talktoastro.feature_home.presentation.model.HomeTopProductCard
import tta.destinigo.talktoastro.shared.local.UserInfoProvider
import tta.destinigo.talktoastro.shared.remote.Resources

class HomePageRepository(
    private val walletApi: WalletBalanceApi,
    private val api:HomeApiService
) {

    suspend fun getWalletBalance():Flow<String>{
        return flow {
            val walletBalanceResponse = walletApi.getWalletMoney()
            if (walletBalanceResponse is Resources.Success){
                val totalWalletMoney =
                    walletBalanceResponse.data?.toIntOrNull()?.toString()
                        ?: walletBalanceResponse.data?.toFloatOrNull().toString()
                UserInfoProvider.setTotalWalletMoney(totalWalletMoney)
                emit(totalWalletMoney)
            }
        }
    }

    suspend fun fetchSliders():Flow<HomeResources<List<RequestSlider>>>{
        return flow {
            val sliderResult = api.requestHomeScreenSlider()
            if (sliderResult is Resources.Success){
                emit(HomeResources.Success(sliderResult.data?.data?.slider ?: emptyList()))
                return@flow
            }
            if (sliderResult is Resources.Error){
                emit(HomeResources.Error(sliderResult.msg))
                return@flow
            }
        }
    }

    suspend fun fetchHomeCategory():Flow<RequestCategory?>{
        return flow {
            val categoryResult = api.requestHomeScreenCategory()
            if (categoryResult is Resources.Success){
                emit(categoryResult.data?.categories?.find { filtered ->
                    filtered.name  =="Astrologer"
                })
            }
        }
    }

    suspend fun fetchTopConsultant():Flow<TopConsultantData> = flow {
        val topConsultantResult = api.requestHomeScreenTopConsultant()
        if (topConsultantResult is Resources.Success){
            emit(topConsultantResult.data?.data?.let { check ->
                TopConsultantData(
                    check.topConsultants ?: emptyList(),
                    check.freeCallAvailability == 1
                )
            } ?: TopConsultantData(emptyList(), false))
        }
    }

    suspend fun fetchLiveSession():Flow<List<RequestUpComingLiveSessions>> = flow {
        val liveSessionResult  = api.requestHomeScreenUpcomingLiveSessions()

        if (liveSessionResult is Resources.Success){
            emit(liveSessionResult.data?.data?.sliderList ?: emptyList())
            return@flow
        }
    }

    suspend fun fetchPosts():Flow<List<PostDataNew>> = flow {
        val posts = api.requestGetAllPost(request = PostRequest("1"))

        if (posts is Resources.Success){
            val topFivePosts = posts.data?.data
                ?.filter { it.postContentType == "image" && !it.postDescription.isNullOrBlank() } // Filter criteria
                ?.take(5)
            emit(topFivePosts ?:  emptyList())
            return@flow
        }
    }

    suspend fun getShorts():Flow<List<InstaList>> = flow {
        val shorts = api.requestGetTodayShorts()

        if (shorts is Resources.Success){
            emit(shorts.data ?: emptyList())
            return@flow
        }
    }

    suspend fun getTopProducts():Flow<HomeTopProductCard> = flow {
        val shorts = api.getTopProductsApi()

        if (shorts is Resources.Success){
            val filteredProductList = processTopProductList(shorts.data ?: emptyList())
            emit(HomeTopProductCard(
                highlightedProduct = filteredProductList.first?.copy(
                    regularPrice = "₹${filteredProductList.first?.regularPrice?.toIntOrNull() ?: 0}",
                    discountedPrice = "₹${filteredProductList.first?.discountedPrice?.toIntOrNull() ?: 0}"
                ),
                products = filteredProductList.second.map {
                    it.copy(
                        regularPrice = "₹${it.regularPrice.toIntOrNull() ?: 0}",
                        discountedPrice = "₹${it.discountedPrice.toIntOrNull() ?: 0}",
                        discountPercent = "${it.discountPercent.toIntOrNull() ?: 0}% OFF"
                    )
                },
                trendingProduct = filteredProductList.third.map {
                    it.copy(
                        regularPrice = "₹${it.regularPrice.toIntOrNull() ?: 0}",
                        discountedPrice = "₹${it.discountedPrice.toIntOrNull() ?: 0}",
                        discountPercent = "${it.discountPercent.toIntOrNull() ?: 0}% OFF"
                    )
                }
            ))
            return@flow
        }
    }

    private fun processTopProductList(products: List<TopProductList>): Triple<TopProductList?, List<TopProductList>, List<TopProductList>> {
        val sortedProducts = products.sortedByDescending { it.rating.toFloatOrNull() ?: 0.0f }
        val topProduct = sortedProducts.firstOrNull()
        val remainingProducts = sortedProducts.drop(1)

        // Split remainingProducts into two equal parts
        val halfSize = remainingProducts.size / 2
        val firstHalf = remainingProducts.take(halfSize)  // First half for remainingProducts
        val secondHalf = remainingProducts.drop(halfSize) // Second half for trendingProducts

        return Triple(topProduct, firstHalf, secondHalf)
    }


}

