package tta.destinigo.talktoastro.feature_home.data.api


import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.get
import io.ktor.client.request.parameter
import io.ktor.client.request.post
import io.ktor.client.request.url
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import tta.destinigo.talktoastro.feature_home.data.request.PostRequest
import tta.destinigo.talktoastro.feature_home.data.response.CategoryData
import tta.destinigo.talktoastro.feature_home.data.response.RequestCategoryResponse
import tta.destinigo.talktoastro.feature_home.data.response.RequestDashboardResponse
import tta.destinigo.talktoastro.feature_home.data.response.RequestSliderResponse
import tta.destinigo.talktoastro.feature_home.data.response.RequestUpcomingLiveSessionResponse
import tta.destinigo.talktoastro.feature_home.data.response.UpcomingLiveData
import tta.destinigo.talktoastro.feature_home.data.response.ecom_product.TopProductList
import tta.destinigo.talktoastro.feature_home.data.response.ecom_product.TopProductResponse
import tta.destinigo.talktoastro.feature_home.data.response.post.PostPagingData
import tta.destinigo.talktoastro.feature_home.data.response.post.PostPagingResponse
import tta.destinigo.talktoastro.feature_home.data.response.shorts.InstaList
import tta.destinigo.talktoastro.feature_home.data.response.shorts.InstaListResponse
import tta.destinigo.talktoastro.shared.remote.KtorApi
import tta.destinigo.talktoastro.shared.remote.Resources
import tta.destinigo.talktoastro.shared.remote.makeApiCall

class HomeApiService : KtorApi() {

    companion object {
        const val GET_REQUEST_SLIDER = "sliders"
        const val GET_REQUEST_LIVE_SESSIONS = "upcoming-live-session"
        const val GET_REQUEST_CATEGORY = "categories"
        const val GET_REQUEST_TOP_CONSULTANT = "user-dashboard"
        const val GET_WALLET_MONEY = "get-wallet-balance"

        const val GET_ALL_POST = "getallpost"

        const val GET_SHORTS = "get-instagram-reels"

        const val GET_TOP_PRODUCTS = "woocommerce-products"

    }

    private suspend fun getProduct(): TopProductResponse {
        return client.get {
            url(GET_TOP_PRODUCTS)
        }.body()
    }


    private suspend fun getAllPost(request: PostRequest): PostPagingResponse {
        return client.get {
            url(GET_ALL_POST)
            parameter("page", request.page)
        }.body()
    }

    private suspend fun getTodayShorts() = client.get {
        url(GET_SHORTS)
    }.body<InstaListResponse>()


    suspend fun requestHomeScreenSlider(): Resources<RequestSliderResponse> {
        return withContext(Dispatchers.Default) {
            try {
                val sliderResponse =
                    client.post { url(GET_REQUEST_SLIDER) }.body<RequestSliderResponse>()
                if (sliderResponse.status) Resources.Success(sliderResponse) else throw Exception(
                    sliderResponse.message
                )
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }


    suspend fun requestHomeScreenUpcomingLiveSessions(): Resources<RequestUpcomingLiveSessionResponse> {
        return withContext(Dispatchers.Default) {
            try {
                val upcomingLiveSessions = client.post(GET_REQUEST_LIVE_SESSIONS)
                    .body<RequestUpcomingLiveSessionResponse>()

                if (upcomingLiveSessions.data != null) {
                    val upcoming = upcomingLiveSessions.data?.sliderList
                    val maxUpcomingList = ArrayList(upcoming?.take(5) ?: emptyList())
                    val upcomingLiveData = UpcomingLiveData(maxUpcomingList)
                    Resources.Success(RequestUpcomingLiveSessionResponse(upcomingLiveData))
                } else {
                    throw Exception("Response data is null")
                }
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }


    suspend fun requestHomeScreenCategory(): Resources<CategoryData> {
        return withContext(Dispatchers.Default) {
            try {
                val categoryResponse =
                    client.post(GET_REQUEST_CATEGORY).body<RequestCategoryResponse>()

                run {
                    val categories = categoryResponse.data.categories
                    Resources.Success(CategoryData(categories))
                }
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }


    suspend fun requestHomeScreenTopConsultant(): Resources<RequestDashboardResponse> {
        return withContext(Dispatchers.Default) {
            try {
                val topConsultantResponse =
                    client.post(GET_REQUEST_TOP_CONSULTANT).body<RequestDashboardResponse>()
                Resources.Success(topConsultantResponse)
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

    suspend fun requestGetAllPost(request: PostRequest): Resources<PostPagingData> {
        return withContext(Dispatchers.Default) {
            try {
                val response = getAllPost(request)
                if (!response.status.equals("success", true)) {
                    return@withContext Resources.Error(
                        "Unexpected Error"
                    )
                }
                return@withContext Resources.Success(response.postPagingData)
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

    suspend fun requestGetTodayShorts(): Resources<List<InstaList>> {
        return withContext(Dispatchers.Default) {
            try {
                val response = getTodayShorts()
                if (response.status.not()) {
                    return@withContext Resources.Error(
                        "Unexpected Error"
                    )
                }
                return@withContext Resources.Success(response.data)
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

    suspend fun getTopProductsApi(): Resources<List<TopProductList>?> {
        return makeApiCall(
            apiCall = { getProduct() },
            transform = { it.topProductList }
        )
    }
}