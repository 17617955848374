package tta.destinigo.talktoastro.feature_home.data.response.shorts

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class InstaListResponse(
    @SerialName("status")
    var status: Boolean = false,
    @SerialName("message")
    var message: String? = "Unexpected Error",
    @SerialName("code")
    var code: Int = 0,
    @SerialName("data")
    val data: List<InstaList>
)