package tta.destinigo.talktoastro.feature_home.presentation.home_tab.home_screen_widget

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import org.jetbrains.compose.resources.Font
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.feature_home.data.response.post.PostDataNew
import tta.destinigo.talktoastro.resources.NotoColorEmoji_Regular
import tta.destinigo.talktoastro.resources.NotoSansDevanagari_SemiCondensed_Regular
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.post_banner
import tta.destinigo.talktoastro.resources.profile_icon_avtar
import tta.destinigo.talktoastro.resources.roboto_regular
import tta.destinigo.talktoastro.resources.share_icon_new
import tta.destinigo.talktoastro.shared.persentation.HeadLineTextWithMoreButton
import tta.destinigo.talktoastro.shared.platform.showToast
import tta.destinigo.talktoastro.shared.theme.Scale
import tta.destinigo.talktoastro.shared.theme.primaryColor


@Composable
fun PostCardList(modifier: Modifier, component: List<PostDataNew>, onSeeAllClick: () -> Unit, onReadPostClick: (PostDataNew) -> Unit) {

    if (component.isEmpty()) return

    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {
        Column(
            modifier = modifier.padding(horizontal = 16.dp)
        ) {

            HeadLineTextWithMoreButton(
                modifier = Modifier,
                title = "Top Post",
                onClickMoreButton = { onSeeAllClick.invoke() }
            )

            LazyRow(
                modifier = Modifier.fillMaxWidth(),
                contentPadding = PaddingValues(horizontal = 8.dp),
                horizontalArrangement = Arrangement.SpaceBetween
            ) {

                item {
                    component.forEach { post ->
                        BlogCard(post, onReadPostClick = { clickPost ->
                            onReadPostClick.invoke(clickPost)
                        })
                        Spacer(modifier = Modifier.width(10.dp))
                    }
                }
            }
        }
    }
}

@Composable
fun BlogCard(post: PostDataNew, onReadPostClick: (PostDataNew) -> Unit) {
    Card(
        modifier = Modifier.clickable { onReadPostClick.invoke(post) }
            .width(250.dp)
            .heightIn(min = 300.dp, max = 300.dp),
        shape = RoundedCornerShape(8.dp),
        colors = CardDefaults.cardColors(Color.White),
        elevation = CardDefaults.cardElevation(Scale.ELEVATION_SMALL)
    ) {
        Column(
            modifier = Modifier.padding(16.dp)
        ) {
            if (post.postContent?.isNotEmpty() == true) {
                AsyncImage(
                    model = post.postContent,
                    contentDescription = "Post Image",
                    modifier = Modifier
                        .height(120.dp)
                        .fillMaxWidth(),
                    contentScale = ContentScale.Crop,
                    error = painterResource(Res.drawable.post_banner)
                )
            }
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(vertical = 8.dp),
                verticalAlignment = Alignment.CenterVertically // Align items vertically in the center
            ) {
                AsyncImage(
                    model = post.consultantProfile,
                    contentDescription = "Author Image",
                    modifier = Modifier
                        .size(25.dp) // Constrain both height and width to 50.dp for a square image
                        .clip(CircleShape) // Optional: Make the image circular
                        .background(Color.Gray), // Optional: Add a background color for loading
                    contentScale = ContentScale.Crop,
                    error = painterResource(Res.drawable.profile_icon_avtar)
                )
                Spacer(modifier = Modifier.width(8.dp)) // Add space between the image and text
                Text(
                    text = post.expertName.orEmpty(),
                    style = MaterialTheme.typography.bodySmall,
                    modifier = Modifier.weight(1f) // Allow the text to take remaining space
                )
            }

            val hindiFont = FontFamily(Font(Res.font.NotoSansDevanagari_SemiCondensed_Regular))
            val englishFont = FontFamily(Font(Res.font.roboto_regular))
            val emojiFont = FontFamily(Font(Res.font.NotoColorEmoji_Regular)) // Replace with the actual emoji font resource if needed.

            val emojiRegex = Regex("[\\uD83C-\\uDBFF\\uDC00-\\uDFFF]+") // Matches emojis
            val hindiRegex = Regex("[\\u0900-\\u097F\\u1CD0-\\u1CFF\\uA8E0-\\uA8FF]+")  // Matches Hindi characters

            // Function to determine the font for each word
            fun getFontForWord(word: String): FontFamily {
                return when {
                    word.contains(emojiRegex) -> emojiFont // Use emoji font if emojis are detected
                    word.contains(hindiRegex) -> hindiFont  // Use Hindi font if Hindi characters are detected
                    else -> englishFont // Default to English font
                }
            }

            // Function to create an AnnotatedString with proper font for each part of the text
            fun createStyledText(postDescription: String): AnnotatedString {
                val words = postDescription.split(" ")
                val annotatedString = buildAnnotatedString {
                    words.forEachIndexed { index, word ->
                        val fontFamily = getFontForWord(word)
                        withStyle(style = SpanStyle(fontFamily = fontFamily)) {
                            append(word)
                        }
                        if (index < words.size - 1) append(" ") // Add space between words
                    }
                }
                return annotatedString
            }

            val styledText = post.postDescription?.let { createStyledText(it) } ?: AnnotatedString("")
            Spacer(modifier = Modifier.height(8.dp))

            Text(
                text = styledText,
                fontSize = 16.sp,
                style = MaterialTheme.typography.bodySmall,
                maxLines = 2,
                overflow = TextOverflow.Ellipsis,
                modifier = Modifier.weight(1f)
            )

            Spacer(modifier = Modifier.height(8.dp))
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {
                Button(
                    onClick = { onReadPostClick.invoke(post) },
                    shape = RoundedCornerShape(Scale.CORNER_MEDIUM)
                ) {
                    Text("Read Post")
                }
                IconButton(onClick = { showToast("Coming Soon") }) {
                    Icon(
                        painter = painterResource(Res.drawable.share_icon_new),
                        //imageVector = Icons.Default.Share,
                        contentDescription = "Share"
                    )
                }
            }
        }
    }
}

