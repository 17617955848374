package tta.destinigo.talktoastro.feature_home.data.api

import co.touchlab.kermit.Logger
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.get
import io.ktor.http.headers
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import tta.destinigo.talktoastro.feature_home.data.response.coin.CoinDetailsResponse
import tta.destinigo.talktoastro.feature_home.data.response.wallet_balance.WalletRupeeResponse
import tta.destinigo.talktoastro.shared.local.TokenProvider
import tta.destinigo.talktoastro.shared.remote.KtorApi
import tta.destinigo.talktoastro.shared.remote.Resources

class WalletBalanceApi : KtorApi() {

    companion object {
        const val GET_WALLET_MONEY = "get-wallet-balance"
        const val WALLET_COIN_DETAILS = "checkout-api"
    }

    suspend fun getWalletMoney(): Resources<String> {
        return withContext(Dispatchers.Default) {
            try {
                val token = TokenProvider.getNewToken() // Retrieve the token from your TokenProvider or equivalent

                val topConsultantResponse = client.get(GET_WALLET_MONEY) {

                    headers {
                        append("Authorization", "Bearer $token")
                    }
                }.body<WalletRupeeResponse>()

                if (topConsultantResponse.status.not()) {
                    return@withContext Resources.Error(
                        topConsultantResponse.message
                    )
                }

                return@withContext Resources.Success(topConsultantResponse.data.balance)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }


    suspend fun getCoin() : Resources<Int>{
        return withContext(Dispatchers.Default) {
            try {
                val token = TokenProvider.getNewToken() // Retrieve the token from your TokenProvider or equivalent
                Logger.d { " FatchWalletBalenceApiService getWalletMoney $token" }
                val walletCoinResponse = client.get(WALLET_COIN_DETAILS) {

                    headers {
                        append("Authorization", "Bearer $token")
                    }
                }.body<CoinDetailsResponse>()

                if (walletCoinResponse.status.not()) {
                    return@withContext Resources.Error(
                        walletCoinResponse.message
                    )
                }

                return@withContext Resources.Success(walletCoinResponse.data?.totalCoin)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

}