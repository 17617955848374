package tta.destinigo.talktoastro.feature_home.presentation.home_tab

import tta.destinigo.talktoastro.shared.data.RequestCategory
import tta.destinigo.talktoastro.shared.data.RechargeData


sealed class HomePageEvent {
    data class OnClickTopConsultant(val expertCategory: Int) : HomePageEvent()
    data object OnClickLogin : HomePageEvent()
    data class OnClickCategory(val category: RequestCategory?) : HomePageEvent()
    data class OnLiveSessionClick(val id: Int) : HomePageEvent()
    data class OnSearchClick(val searchText: String) : HomePageEvent()
    data object OnClickProfileIcon : HomePageEvent()
    data object OnClickPrivacyPolicy : HomePageEvent()
    data object OnClickWalletIcon : HomePageEvent()
    data object OnClickTermsAndConditions : HomePageEvent()
    data object OnArticleClick: HomePageEvent()
    // Post
    data object OnClickPostSeeAll: HomePageEvent()
    data class OnClickReadPost(val id: String): HomePageEvent()


    data class OnChatConsultationStart(
        val consultantId: Int,
        val consultantName: String,
        val consultantImage: String,
        val consultantSkills: String,
        val chatDuration: String,
        val category: String,
        val callType:String = ""
    ) : HomePageEvent()

    data class OnRequiredRecharge(val rechargeData: RechargeData?) : HomePageEvent()

    data class OnCallConsultationStart(
        val consultantId: Int,
        val consultantName: String,
        val consultantImage: String,
        val consultantSkills: String,
        val callType:String = ""
    ) : HomePageEvent()

//    data class OnVoIPCallConsultationStart(
//        val duration: String,
//        val expertInfo: CommonExpertInfo
//    ) : HomePageEvent()

//    data class OnNavigateCallWaitingPage(
//        val expertInfo:CommonExpertInfo,
//        val onClose: () -> Unit,
//    ) : HomePageEvent()

    data object onLandingPageClick : HomePageEvent()
}