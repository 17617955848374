package tta.destinigo.talktoastro.feature_home.data.response.post

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PostPagingResponse(
    @SerialName("Data") val postPagingData: PostPagingData,
    @SerialName("responsecode") val responsecode: String, // 200
    @SerialName("status") val status: String // success
)
