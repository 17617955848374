package tta.destinigo.talktoastro.feature_home.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.shared.data.RequestConsultant

@Serializable
data class DashboardData(
    @SerialName("free_call_availability")
    val freeCallAvailability: Int? = null,
    @SerialName("top_consultants")
    val topConsultants: List<RequestConsultant>?
)