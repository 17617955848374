package tta.destinigo.talktoastro.feature_home.presentation.home_tab.home_screen_widget

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Search
import androidx.compose.material3.CardElevation
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import tta.destinigo.talktoastro.shared.theme.Scale

@Composable
fun SearchField(
    modifier: Modifier = Modifier, onClick: () -> Unit
) {

    Row(
        modifier = modifier.fillMaxWidth().clickable(onClick = onClick),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Icon(imageVector = Icons.Default.Search,
            contentDescription = "Search Icon",
            tint = Color.Gray.copy(alpha = 0.9f),
            modifier = Modifier.padding(horizontal = 8.dp, vertical = 8.dp)
                .size(Scale.GLOBAL_ICON_SIZE))

        Text(
            text = "Any expert in just a click...",
            modifier = Modifier.weight(1f),
            style = MaterialTheme.typography.titleSmall.copy(
                color = Color.Gray.copy(alpha = 0.9f)
            ),
            maxLines = 1,
        )
    }
}

//@Composable
//fun ExpertSearchField(
//    modifier: Modifier = Modifier, onClick: @Composable () -> Unit
//) {
//
//    Row(
//        modifier = modifier.fillMaxWidth().clickable(onClick = onClick),
//        verticalAlignment = Alignment.CenterVertically
//    ) {
//        Icon(imageVector = Icons.Default.Search,
//            contentDescription = "Search Icon",
//            tint = Color.Gray.copy(alpha = 0.8f),
//            modifier = Modifier.padding(horizontal = 8.dp, vertical = 8.dp).size(Scale.GLOBAL_ICON_SIZE)
//                .clickable { onClick() })
//
//        Text(
//            text = "Search Expert hare",
//            modifier = Modifier.weight(1f),
//            style = MaterialTheme.typography.labelSmall,
//            maxLines = 1,
//        )
//    }
//}

@Composable
internal fun SearchFieldPreview(
    modifier: Modifier,
    onClick: (String) -> Unit,
    elevation: Dp = 0.dp
) {

    Surface(
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        shadowElevation = elevation,
        modifier = modifier.padding(
            start = Scale.HORIZONTAL_PADDING,
            end = Scale.HORIZONTAL_PADDING
        )
    ) {
        SearchField(
            modifier = Modifier, onClick = { onClick("") }
        )
    }

}

@Composable
fun ExpertSearchField(
    modifier: Modifier = Modifier, onClick: () -> Unit
) {
    Row(
        modifier = modifier
            .fillMaxWidth()
            .clickable { onClick() },
        verticalAlignment = Alignment.CenterVertically
    ) {
        Icon(
            imageVector = Icons.Default.Search,
            contentDescription = "Search Icon",
            tint = Color.Gray.copy(alpha = 0.8f),
            modifier = Modifier
                .padding(horizontal = 8.dp, vertical = 8.dp)
                .size(Scale.GLOBAL_ICON_SIZE)
                .clickable { onClick() }
        )

        Text(
            text = "Search Expert here",
            modifier = Modifier.weight(1f),
            style = MaterialTheme.typography.labelSmall,
            maxLines = 1
        )
    }
}

//@Composable
//fun ExpertSearchFieldPreview(component: ExpertListPageComponent) {
//    Card(
//        shape = RoundedCornerShape(Scale.CORNER_SMALL),
//        colors = CardDefaults.cardColors(Color.White),
//        elevation = CardDefaults.cardElevation(Scale.ELEVATION_SMALL),
//        modifier = Modifier
//            .padding(start = Scale.HORIZONTAL_PADDING, bottom = Scale.HORIZONTAL_PADDING, end = Scale.HORIZONTAL_PADDING)
//            .fillMaxWidth()
//    ) {
//        ExpertSearchField(
//            modifier = Modifier,
//            onClick = { component.event(ExpertListPageEvent.OnSearchFieldClick) }//component.event(ExpertListPageEvent.OnSearchFieldClick) }
//        )
//    }
//}
