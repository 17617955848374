package tta.destinigo.talktoastro.feature_home.presentation.ecom_list

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.StarHalf
import androidx.compose.material.icons.filled.LocalOffer
import androidx.compose.material.icons.filled.Star
import androidx.compose.material.icons.filled.StarOutline
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.BlendMode
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import coil3.compose.AsyncImage
import tta.destinigo.talktoastro.shared.theme.Scale
import tta.destinigo.talktoastro.shared.theme.primaryColor
import tta.destinigo.talktoastro.shared.theme.primaryDarkerColor

@Composable
fun ProductCardTrending(
    modifier: Modifier,
    shopItemImage: String,
    rating: String,
    discountText: String?,
    productName: String,
    discountedPrice: String,
    actualPrice: String,
    onAddToCartClick: () -> Unit,
    windowWidthSizeClass: WindowWidthSizeClass
) {

    val withModifier = when (windowWidthSizeClass) {
        WindowWidthSizeClass.Expanded -> modifier.width(190.dp)
        WindowWidthSizeClass.Medium -> modifier.width(180.dp)
        else -> modifier.width(160.dp)
    }

    Card(
        modifier = withModifier
            .wrapContentHeight()
            .clickable {
                onAddToCartClick.invoke()
            },
        colors = CardDefaults.cardColors(Color.White),
        elevation = CardDefaults.cardElevation(defaultElevation = Scale.ELEVATION_SMALL),
        shape = RoundedCornerShape(Scale.CORNER_SMALL)
    ) {

        Box(
            modifier = Modifier
                .fillMaxWidth()
                .aspectRatio(1f),
        ) {

            // Product Image
            AsyncImage(
                model = shopItemImage,
                contentDescription = "Shop Item Image",
                contentScale = ContentScale.Crop
            )

            // Discount Badge
            if (!discountText.isNullOrEmpty()) {
                StripText(textString = discountText, icon = Icons.Default.LocalOffer)
            }
        }

        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier.padding(8.dp)
        ) {
            // Product Name
            Text(
                modifier = Modifier.fillMaxWidth().align(Alignment.Start),
                text = productName,
                style = MaterialTheme.typography.bodyMedium,
                fontWeight = FontWeight.SemiBold,
                maxLines = 2,
                minLines = 2,
                overflow = TextOverflow.Ellipsis
            )

            // Rating Bar
            StarRatingIcon(
                modifier = Modifier.fillMaxWidth(),
                rating = rating.toFloat(),
            )

            Spacer(modifier = Modifier.height(4.dp))
            // Price Row
            PriceSection(
                modifier = Modifier.fillMaxWidth(),
                actualPrice = actualPrice,
                discountedPrice = discountedPrice,
                style = MaterialTheme.typography.bodyLarge.copy(
                    fontWeight = FontWeight.Bold
                )
            )
        }
    }
}

@Composable
fun ProductCardSmall(
    modifier: Modifier,
    shopItemImage: String,
    rating: String,
    discountText: String?,
    productName: String,
    discountedPrice: String,
    actualPrice: String,
    onAddToCartClick: () -> Unit,
    windowWidthSizeClass: WindowWidthSizeClass
) {

    val withModifier = when (windowWidthSizeClass) {
        WindowWidthSizeClass.Expanded -> modifier.width(190.dp)
        WindowWidthSizeClass.Medium -> modifier.width(180.dp)
        else -> modifier.width(160.dp)
    }

    Surface (
        modifier = withModifier
            .wrapContentHeight(),
        shadowElevation = Scale.ELEVATION_SMALL,
        shape = RoundedCornerShape(Scale.CORNER_SMALL)
    ) {

        Box(
            modifier = Modifier.fillMaxSize().clickable {
                onAddToCartClick.invoke()
            }
        ) {

            // Product Image
            AsyncImage(
                model = shopItemImage,
                contentDescription = "Shop Item Image",
                modifier = Modifier
                    .fillMaxSize()
                    .clip(RoundedCornerShape(Scale.CORNER_SMALL)),
                contentScale = ContentScale.Crop,
                colorFilter = ColorFilter.tint(
                    color = Color.Black.copy(alpha = 0.1f),
                    blendMode = BlendMode.SrcAtop
                )
            )

            Column(
                modifier = Modifier.padding(8.dp).fillMaxSize(),
                horizontalAlignment = Alignment.Start,
                verticalArrangement = Arrangement.Bottom
            ) {

                // Rating Bar
                StarRatingIcon(
                    rating = rating.toFloat(),
                )

                // Price Row
                PriceSection(
                    actualPrice = actualPrice,
                    discountedPrice = discountedPrice,
                    style = MaterialTheme.typography.titleSmall,
                    primaryColor = Color.White,
                    secondaryColor = Color.White.copy(alpha = 0.5f)
                )
            }


        }

    }
}

@Composable
fun ProductCardBig(
    shopItemImage: String,
    rating: String,
    discountText: String?,
    productName: String,
    discountedPrice: String,
    actualPrice: String,
    onAddToCartClick: () -> Unit,
    windowWidthSizeClass: WindowWidthSizeClass
) {

    val withModifier = when (windowWidthSizeClass) {
        WindowWidthSizeClass.Expanded -> Modifier.width(280.dp)
        WindowWidthSizeClass.Medium -> Modifier.width(220.dp)
        else -> Modifier.fillMaxWidth()
    }

    Surface (
        modifier = withModifier
            .wrapContentHeight(),
        shadowElevation = Scale.ELEVATION_SMALL,
        shape = RoundedCornerShape(Scale.CORNER_SMALL)
    ) {

        Box(
            modifier = Modifier.fillMaxSize().clickable {
                onAddToCartClick.invoke()
            }
        ) {

            // Product Image
            AsyncImage(
                model = shopItemImage,
                contentDescription = "Shop Item Image",
                modifier = Modifier
                    .fillMaxSize()
                    .clip(RoundedCornerShape(Scale.CORNER_SMALL)),
                contentScale = ContentScale.Crop,
                colorFilter = ColorFilter.tint(
                    color = Color.Black.copy(alpha = 0.1f),
                    blendMode = BlendMode.SrcAtop
                )
            )

            Column(
                modifier = Modifier.padding(12.dp),
                horizontalAlignment = Alignment.Start,
                verticalArrangement = Arrangement.SpaceBetween
            ) {

                // Discount Badge
                if (!discountText.isNullOrEmpty()) {
                    Box(
                        modifier = Modifier.weight(1f)
                    ){
                        StripText(textString = "Most Popular")
                    }

                }

                // Product Name
                Text(
                    text = productName,
                    style = MaterialTheme.typography.bodyLarge,
                    fontWeight = FontWeight.SemiBold,
                    color = Color.White,
                    maxLines = 1,
                    overflow = TextOverflow.Ellipsis
                )
                // Rating Bar
                StarRatingIcon(
                    rating = rating.toFloat(),
                )

                Spacer(modifier = Modifier.height(4.dp))
                // Price Row
                PriceSection(
                    actualPrice = actualPrice,
                    discountedPrice = discountedPrice,
                    primaryColor = Color.White,
                    secondaryColor = Color.White.copy(alpha = 0.5f)
                )
            }


        }

    }
}

@Composable
fun StripText(
    modifier: Modifier = Modifier,
    icon: ImageVector? = null,
    textString: String,
) {
    Box(
        modifier = modifier
            .background(color = Color(0xFFE01A2B), shape = RoundedCornerShape(4.dp))
            .padding(horizontal = 5.dp, vertical = 2.dp)
    ) {

        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center
        ) {
            if (icon != null){
                Icon(
                    imageVector = icon ,
                    contentDescription = null,
                    tint = Color.White,
                    modifier = Modifier.size(12.dp)
                )
                Spacer(modifier = Modifier.width(4.dp))
            }

            Text(
                text = textString,
                color = Color.White,
                style = MaterialTheme.typography.labelSmall,
                fontWeight = FontWeight.Bold
            )
        }


    }
}

@Composable
fun PriceSection(
    modifier: Modifier = Modifier,
    discountedPrice: String,
    actualPrice: String,
    style: TextStyle = MaterialTheme.typography.headlineMedium,
    primaryColor: Color = primaryDarkerColor,
    secondaryColor: Color = Color.LightGray
) {
    Row(
        modifier = modifier,
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Start
    ) {

        Text(
            text = discountedPrice,
            style = style,
            color = primaryColor,
            fontWeight = FontWeight.ExtraBold,
        )

        Spacer(modifier = Modifier.width(8.dp))

        Text(
            text = actualPrice,
            style = MaterialTheme.typography.bodyLarge,
            textDecoration = TextDecoration.LineThrough,
            color = secondaryColor
        )
    }
}

@Composable
fun StarRatingIcon(
    rating: Float, // Current rating
    maxRating: Int = 5, // Total stars
    modifier: Modifier = Modifier,
    starSize: Dp = 14.dp,
    filledStarColor: Color = primaryColor, // Gold color
    emptyStarColor: Color = Color.Gray
) {
    Row(
        modifier = modifier,
        horizontalArrangement = Arrangement.Start,
        verticalAlignment = Alignment.CenterVertically
    ) {
        for (i in 1..maxRating) {
            val starIcon = when {
                i <= rating -> Icons.Default.Star // Fully filled star
                i - 0.5 <= rating -> Icons.AutoMirrored.Filled.StarHalf // Half-filled star
                else -> Icons.Default.StarOutline // Empty star
            }
            Icon(
                imageVector = starIcon,
                contentDescription = "Star $i",
                tint = if (i <= rating) filledStarColor else emptyStarColor,
                modifier = Modifier.size(starSize)
            )
        }

        Spacer(modifier = Modifier.width(4.dp)) // Add spacing between stars and text()

        Text(
            text = "($rating)",
            style = MaterialTheme.typography.labelSmall.copy(
                fontWeight = FontWeight.SemiBold
            ),
            color = emptyStarColor
        )
    }
}
