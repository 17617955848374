package tta.destinigo.talktoastro.feature_home.presentation.home_tab.home_screen_widget

import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Surface
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.shared.data.RequestCategory
import tta.destinigo.talktoastro.feature_home.presentation.home_tab.HomeScreenTabComponent
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.astrology
import tta.destinigo.talktoastro.resources.courses
import tta.destinigo.talktoastro.resources.shop
import tta.destinigo.talktoastro.resources.spirtual
import tta.destinigo.talktoastro.shared.config.ASTROLOGY_LIST
import tta.destinigo.talktoastro.shared.config.COURSES_LIST
import tta.destinigo.talktoastro.shared.config.CUSTOM_ORDER_LIST
import tta.destinigo.talktoastro.shared.config.STORE_LIST
import tta.destinigo.talktoastro.shared.config.WINYWAY_SHOP_LINK
import tta.destinigo.talktoastro.shared.platform.openUrlInBrowser
import tta.destinigo.talktoastro.shared.theme.Scale

@Composable
fun TopCategoryList(
    onCategoryClick: (RequestCategory) -> Unit,
    component: HomeScreenTabComponent,
    modifier: Modifier,
    windowWidthSizeClass: WindowWidthSizeClass
) {
    val categoryState by component.topCategory.collectAsState()

    val categories = listOf(
        Pair(Res.drawable.astrology, ASTROLOGY_LIST),
        Pair(Res.drawable.spirtual, CUSTOM_ORDER_LIST),
        Pair(Res.drawable.courses, COURSES_LIST),
        Pair(Res.drawable.shop, STORE_LIST)
    )

    val categoryClickHandler: (String) -> Unit = { category ->
        println("category -> $category")
        when (category) {
            CUSTOM_ORDER_LIST, COURSES_LIST -> {
                onCategoryClick.invoke(RequestCategory(
                    name = category,
                    id = 9,
                    image_url = "",
                    subcategories = null,
                    image = ""
                ))
            }
            STORE_LIST ->{
                openUrlInBrowser(WINYWAY_SHOP_LINK)
            }
            else -> {
                println("category -> $category")
                if (categoryState != null) {
                    println("categoryState -> $categoryState")
                    onCategoryClick.invoke(categoryState!!)
                }else{
                    println("category state is null")
                }
            }
        }
    }

    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {
        if (windowWidthSizeClass == WindowWidthSizeClass.Compact) {
            CompactCategoryLayout(categories, categoryClickHandler, modifier)
        } else {
            ExpandedCategoryLayout(categories, categoryClickHandler, modifier)
        }
    }
}

@Composable
fun CompactCategoryLayout(
    categories: List<Pair<DrawableResource, String>>,
    onCategoryClick: (String) -> Unit,
    modifier: Modifier
) {
    Column(verticalArrangement = Arrangement.spacedBy(8.dp)) {
        categories.chunked(2).forEach { rowItems ->
            Row(
                modifier = modifier
                    .wrapContentHeight()
                    .padding(horizontal = Scale.HORIZONTAL_PADDING),
                horizontalArrangement = Arrangement.spacedBy(8.dp)
            ) {
                rowItems.forEach { (resImage, category) ->
                    Box(modifier = Modifier.weight(1f)) {
                        CategoryCard(
                            category = category,
                            resImage = resImage,
                            onClickCard = onCategoryClick,
                            windowWidthSizeClass = WindowWidthSizeClass.Compact
                        )
                    }
                }
            }
        }
    }
}

@Composable
fun ExpandedCategoryLayout(
    categories: List<Pair<DrawableResource, String>>,
    onCategoryClick: (String) -> Unit,
    modifier: Modifier
) {
    Row(
        modifier = modifier
            .wrapContentHeight()
            .padding(horizontal = Scale.HORIZONTAL_PADDING),
        horizontalArrangement = Arrangement.spacedBy(8.dp)
    ) {
        categories.forEach { (resImage, category) ->
            Box(modifier = Modifier.weight(1f)) {
                CategoryCard(
                    category = category,
                    resImage = resImage,
                    onClickCard = onCategoryClick,
                    windowWidthSizeClass = WindowWidthSizeClass.Expanded
                )
            }
        }
    }
}

@Composable
fun CategoryCard(
    category: String,
    resImage: DrawableResource,
    onClickCard: (String) -> Unit,
    windowWidthSizeClass: WindowWidthSizeClass
) {

    val sizeOfImage = when(windowWidthSizeClass){
        WindowWidthSizeClass.Expanded -> 90.dp
        WindowWidthSizeClass.Medium -> 75.dp
        else -> 65.dp
    }

    Surface(
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        shadowElevation = Scale.ELEVATION_SMALL,
        modifier = Modifier
            .fillMaxWidth()
            .wrapContentHeight()
    ) {
        // Box to overlay the Image and the content
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .wrapContentHeight()
                .clickable { onClickCard.invoke(category) }
        ) {
            // Background image
            Image(
                painter = painterResource(resImage),
                contentDescription = "",
                contentScale = ContentScale.Crop,
                modifier = Modifier
                    .fillMaxWidth()
                    .height(sizeOfImage) // Adjust the height as per the image aspect ratio
            )

            // Overlay Box for the category text and icon
//            Box(
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .align(Alignment.BottomStart)
//                    .padding(horizontal = 12.dp, vertical = 12.dp)
//
//            ) {
//                Column(
//                    horizontalAlignment = Alignment.Start,
//                    verticalArrangement = Arrangement.Bottom,
//                    modifier = Modifier
//                        .wrapContentSize()
//                ) {
//                    Text(
//                        text = split[0],
//                        style = MaterialTheme.typography.bodyMedium,
//                        fontWeight = FontWeight.SemiBold,
//                                color = Color.White
//                    )
//
//                    Row(
//                        horizontalArrangement = Arrangement.Start,
//                        verticalAlignment = Alignment.CenterVertically
//                    ) {
//
//                        Text(
//                            text = split[1],
//                            style = MaterialTheme.typography.bodyMedium,
//                            fontWeight = FontWeight.SemiBold,
//                            color = Color.White
//                        )
//
//
//                        Spacer(modifier = Modifier.width(2.dp))
//
//                        Icon(
//                            imageVector = Icons.AutoMirrored.Filled.KeyboardArrowRight,
//                            contentDescription = "Arrow Right Icon",
//                            modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE),
//                                    tint = Color.White
//                        )
//
//                    }
//                }
//            }
        }
    }
}


//
//@Composable
//fun CategoryCard(
//    category: String,
//    resImage: DrawableResource,
//    onClickCard: (String) -> Unit,
//) {
//    Surface(
//        shape = RoundedCornerShape(Scale.CORNER_SMALL),
//        shadowElevation = Scale.ELEVATION_SMALL,
//        modifier = Modifier
//            .fillMaxWidth()
//            .wrapContentHeight()
//
//    ) {
//
//        Image(
//            painter = painterResource(resource = resImage),
//            contentDescription = "",
//            contentScale = ContentScale.FillHeight,
//            modifier = Modifier
//                .fillMaxSize().clickable { onClickCard.invoke(category) }
//        )
//
//        Box(
//            modifier = Modifier.fillMaxSize(),
//            contentAlignment = Alignment.BottomStart
//        ) {
//            Row(
//                horizontalArrangement = Arrangement.Center,
//                verticalAlignment = Alignment.CenterVertically,
//                modifier = Modifier
//                    .width(100.dp)
//                    .wrapContentHeight()
//                    .background(color = Color.Black.copy(alpha = 0.5f))
//                    .padding(horizontal = 8.dp, vertical = 4.dp)
//            ) {
//
//                Text(
//                    text = category,
//                    color = Color.White
//                )
//
//                Spacer(modifier = Modifier.width(4.dp))
//
//                Icon(
//                    imageVector = Icons.Default.KeyboardArrowRight,
//                    contentDescription = "Arrow Right Icon",
//                    tint = Color.White,
//                    modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE)
//                )
//            }
//
//        }
//    }
//}
