package tta.destinigo.talktoastro.feature_home.presentation.home_tab.home_screen_widget


import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.PlayArrow
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.BlendMode
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import coil3.compose.AsyncImage
import tta.destinigo.talktoastro.feature_home.data.response.shorts.InstaList
import tta.destinigo.talktoastro.feature_home.data.response.shorts.Shorts
import tta.destinigo.talktoastro.shared.persentation.HeadLineTextWithMoreButton
import tta.destinigo.talktoastro.shared.theme.Scale

@Composable
fun VideoCardList(modifier: Modifier, component: List<InstaList>, onSeeAllClick: () -> Unit) {

    if (component.isEmpty()) return

    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {
        Column(
            modifier = modifier.padding(horizontal = 16.dp)
        ) {

            HeadLineTextWithMoreButton(
                title = "Daily Shorts",
                onClickMoreButton = { onSeeAllClick.invoke() },
                modifier = Modifier
            )

            LazyRow(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.spacedBy(10.dp)
            ) {
                items(component.size) { inx ->
                    val data = component[inx]
                    VideoCard(data)
                }

            }
        }
    }
}


@Composable
fun VideoCard(dalyShorts: InstaList) {

    Card(
        modifier = Modifier
            .width(150.dp)
            .heightIn(min = 250.dp, max = 250.dp),
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        elevation = CardDefaults.cardElevation(Scale.ELEVATION_SMALL)
    ) {

        Box(modifier = Modifier.fillMaxSize()) {
            val data = parseEmbedCode(insta = dalyShorts)
            
            AsyncImage(
                model = data.thumbnail,
                contentDescription = "Video Thumbnail",
                modifier = Modifier.fillMaxSize(),
                colorFilter = ColorFilter.tint(color = Color.Black.copy(alpha = 0.1f), blendMode = BlendMode.SrcAtop),
                contentScale = ContentScale.Crop
            )
            // Play button overlay
            Box(
                modifier = Modifier
                    .align(Alignment.Center)
                    .size(50.dp)
                    .background(Color.Black.copy(alpha = 0.6f), CircleShape)
            ) {
                Icon(
                    imageVector = Icons.Default.PlayArrow,
                    contentDescription = "Play Video",
                    tint = Color.White,
                    modifier = Modifier.align(Alignment.Center)
                )
            }
        }
    }
}


fun parseEmbedCode(insta: InstaList): Shorts {
    insta.embedCode?.let { embedCode ->
        // Extract video ID
        val videoId = embedCode.substringAfter("embed/").substringBefore("?")
        // Extract title
        val title = embedCode.substringAfter("title=\"").substringBefore("\"")
        // Extract src
        val src = embedCode.substringAfter("src=\"").substringBefore("\"")
        // Extract allow attributes
        val allow = embedCode.substringAfter("allow=\"").substringBefore("\"")
        // Extract referrerpolicy
        val referrerPolicy = embedCode.substringAfter("referrerpolicy=\"").substringBefore("\"")
        // Extract allowfullscreen (true if found, otherwise false)
        val allowFullscreen = embedCode.contains("allowfullscreen")

        // Return a Shorts object with the extracted data
        return Shorts(
            title = title,
            videoId = videoId,
            thumbnail = insta.thumbnail,
            expertName = insta.expertName,
            expertImage = insta.profileImage
        )
    }

    // Return a default Shorts object if embedCode is null
    return Shorts(
        title = null,
        videoId = null,
        thumbnail = insta.thumbnail,
        expertName = insta.expertName,
        expertImage = insta.profileImage
    )
}

