package tta.destinigo.talktoastro.feature_home.presentation.home_tab.home_screen_widget

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.foundation.pager.HorizontalPager
import androidx.compose.foundation.pager.PageSize
import androidx.compose.foundation.pager.PagerDefaults
import androidx.compose.foundation.pager.rememberPagerState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.feature_home.data.request.RequestSlider
import tta.destinigo.talktoastro.feature_home.domain.HomeResources
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.image_loading_square
import tta.destinigo.talktoastro.shared.theme.Scale
import tta.destinigo.talktoastro.shared.theme.md_theme_light_secondary
import kotlin.math.absoluteValue

@OptIn(
    ExperimentalFoundationApi::class, ExperimentalMaterial3WindowSizeClassApi::class
)
@Composable
fun WelcomeSlide(
    sliders: HomeResources<List<RequestSlider>>,
    modifier: Modifier
) {

    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val widthModifier = when (windowSizeClass) {
//        WindowWidthSizeClass.Medium -> Modifier.height(300.dp)
        WindowWidthSizeClass.Expanded -> Modifier.height(280.dp)
        else -> Modifier.wrapContentSize()
    }

    if (sliders is HomeResources.Loading){
        Column (
            modifier = Modifier.fillMaxWidth().padding(bottom = 12.dp),
            horizontalAlignment = Alignment.CenterHorizontally
        ){
            Box(
                modifier = modifier.fillMaxWidth().padding(horizontal = Scale.HORIZONTAL_PADDING),
                contentAlignment = Alignment.Center
            ) {
                Card (
                    modifier = widthModifier.fillMaxWidth(),
                    elevation = CardDefaults.cardElevation(Scale.ELEVATION_SMALL),
                    shape = RoundedCornerShape(Scale.CORNER_SMALL),
                ){
                    Column(
                        modifier = Modifier.fillMaxSize(),
                        horizontalAlignment = Alignment.CenterHorizontally,
                        verticalArrangement = Arrangement.Center
                    ) {
                        Text(text = "Loading", color = Color.LightGray, fontSize = 12.sp)
                        Spacer(modifier = Modifier.height(2.dp))
                        CircularProgressIndicator(
                            color = Color.LightGray,
                            strokeWidth =  2.dp,
                            modifier = Modifier.size(18.dp)
                        )
                    }
                }
            }

        }
    }

    if (sliders is HomeResources.Success){
        sliders.data?.let {
            val coroutineScope = rememberCoroutineScope()
            val pagerState = rememberPagerState(
                initialPage = 0,
                initialPageOffsetFraction = 0f,
                pageCount = { it.size }
            )

            LaunchedEffect(key1 = sliders) {
                coroutineScope.launch {
                    while (true) {
                        delay(3000)
                        pagerState.animateScrollToPage((pagerState.currentPage + 1) % it.size)
                    }
                }
            }

            Box(
                modifier = Modifier.fillMaxWidth(),
                contentAlignment = Alignment.Center
            ) {
                Column(
                    modifier = modifier,
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.Center
                ) {
                    HorizontalPager(
                        modifier = Modifier,
                        state = pagerState,
                        userScrollEnabled = true,
                        reverseLayout = false,
                        pageSize = PageSize.Fill,
                        flingBehavior = PagerDefaults.flingBehavior(state = pagerState),
                        pageContent = { page ->
                            val imageUrl = it.getOrNull(page)?.image
                            SlideContent(imageUrl = imageUrl, widthModifier)
                        }
                    )

                    HorizontalPagerIndicator(
                        pageCount = it.size,
                        currentPage = pagerState.currentPage,
                        targetPage = pagerState.targetPage,
                        currentPageOffsetFraction = pagerState.currentPageOffsetFraction,
                        unselectedIndicatorSize = 14.dp,
                        selectedIndicatorSize = 15.dp,
                        indicatorCornerRadius = 5.dp,
                        indicatorPadding = 5.dp,
                        indicatorColor = md_theme_light_secondary
                    )
                }
            }
        }
    }
}

@Composable
fun SlideContent(
    imageUrl: String?,
    modifier: Modifier
) {

    if (imageUrl != null) {
        Card(
            shape = RoundedCornerShape(Scale.CORNER_SMALL),
            elevation = CardDefaults.cardElevation(Scale.ELEVATION_SMALL),
            modifier = modifier
                .fillMaxWidth()
                .padding(horizontal = Scale.HORIZONTAL_PADDING)
        ) {

            AsyncImage(
                model = imageUrl,
                contentDescription = null,
                modifier = modifier
                    .fillMaxWidth(),
                contentScale = ContentScale.FillWidth,
            )
        }
    } else {
        Box(
            modifier = Modifier.fillMaxSize(),
            contentAlignment = Alignment.Center
        ) {
            Image(
                //painter = painterResource(getResourceUri(imageUrl)),
               // painter = painterResource(Res.drawable.image_loading_square),
                painter = painterResource(Res.drawable.image_loading_square),
                contentDescription = null
            )
        }
    }
}


@Composable
fun HorizontalPagerIndicator(
    pageCount: Int,
    currentPage: Int,
    targetPage: Int,
    currentPageOffsetFraction: Float,
    modifier: Modifier = Modifier,
    indicatorColor: Color = Color.DarkGray,
    unselectedIndicatorSize: Dp = 8.dp,
    selectedIndicatorSize: Dp = 10.dp,
    indicatorCornerRadius: Dp = 2.dp,
    indicatorPadding: Dp = 2.dp
) {
    Row(
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically,
        modifier = modifier
            .wrapContentSize()
            .height(selectedIndicatorSize + indicatorPadding * 2)
    ) {

        // draw an indicator for each page
        repeat(pageCount) { page ->
            // calculate color and size of the indicator
            val (color, size) =
                if (currentPage == page || targetPage == page) {
                    // calculate page offset
                    val pageOffset =
                        ((currentPage - page) + currentPageOffsetFraction).absoluteValue
                    // calculate offset percentage between 0.0 and 1.0
                    val offsetPercentage = 1f - pageOffset.coerceIn(0f, 1f)

                    val size =
                        unselectedIndicatorSize + ((selectedIndicatorSize - unselectedIndicatorSize) * offsetPercentage)

                    indicatorColor.copy(
                        alpha = offsetPercentage
                    ) to size
                } else {
                    indicatorColor.copy(alpha = 0.1f) to unselectedIndicatorSize
                }

            // draw indicator
            Box(
                modifier = Modifier
                    .padding(
                        // apply horizontal padding, so that each indicator is same width
                        horizontal = ((selectedIndicatorSize + indicatorPadding * 2) - size) / 2,
                        vertical = size / 4
                    )
                    .clip(RoundedCornerShape(indicatorCornerRadius))
                    .background(color)
                    .width(size)
                    .height(size / 2)
            )
        }
    }
}

