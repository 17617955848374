package tta.destinigo.talktoastro.feature_home.data.response.post

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PostPagingData(
    @SerialName("current_page") val currentPage: Int, // 1
    @SerialName("data") val `data`: List<PostDataNew>,
    @SerialName("first_page_url") val firstPageUrl: String, // https://live.winyway.com/api/v1/getallpost?page=1
    @SerialName("from") val from: Int, // 1
    @SerialName("last_page") val lastPage: Int, // 448
    @SerialName("last_page_url") val lastPageUrl: String, // https://live.winyway.com/api/v1/getallpost?page=448
    @SerialName("next_page_url") val nextPageUrl: String, // https://live.winyway.com/api/v1/getallpost?page=2
    @SerialName("path") val path: String, // https://live.winyway.com/api/v1/getallpost
    @SerialName("per_page") val perPage: Int, // 1
    @SerialName("prev_page_url") val prevPageUrl: String?, // null
    @SerialName("to") val to: Int, // 1
    @SerialName("total") val total: Int // 448
)
