package tta.destinigo.talktoastro.feature_home.presentation.home_tab


import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnCreate
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import tta.destinigo.talktoastro.shared.data.RequestCategory
import tta.destinigo.talktoastro.feature_home.data.request.RequestSlider
import tta.destinigo.talktoastro.feature_home.data.request.RequestUpComingLiveSessions
import tta.destinigo.talktoastro.feature_home.data.response.post.PostDataNew
import tta.destinigo.talktoastro.feature_home.data.response.shorts.InstaList
import tta.destinigo.talktoastro.feature_home.domain.HomePageRepository
import tta.destinigo.talktoastro.feature_home.domain.HomeResources
import tta.destinigo.talktoastro.feature_home.presentation.model.HomeTopProductCard
import tta.destinigo.talktoastro.shared.local.UserInfoProvider

class  HomeScreenTabComponent(
    context: ComponentContext,
    private val onHomePageEvent: (HomePageEvent) -> Unit
) : ComponentContext by context, KoinComponent {

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Default)

    private val scope = getKoin().createScope<HomeScreenTabComponent>()
    private val repository: HomePageRepository by scope.inject()


    private val isUserLogin = UserInfoProvider.getIsLogin()

    /*Start for here component*/

    private val _slidersData =
        MutableStateFlow<HomeResources<List<RequestSlider>>>(HomeResources.Loading())
    val slidersData = _slidersData.asStateFlow()

    private val _topCategory = MutableStateFlow<RequestCategory?>(null)
    val topCategory = _topCategory.asStateFlow()

    private val _topConsultant = MutableStateFlow<TopConsultantData?>(null)
    val topConsultant = _topConsultant.asStateFlow().distinctUntilChanged { old, new ->
        old == new
    }

//    private val _liveSessionState = MutableStateFlow<List<RequestUpComingLiveSessions>?>(null)
//    val liveSessionState = _liveSessionState.asStateFlow().distinctUntilChanged { old, new ->
//        old == new
//    }

    private val _walletState = MutableStateFlow(UserInfoProvider.getTotalWalletMoney())
    val walletState = _walletState.asStateFlow().distinctUntilChanged { old, new ->
        old == new
    }

    private val _postState = MutableStateFlow<List<PostDataNew>>(emptyList())
    val postState = _postState.asStateFlow().distinctUntilChanged { old, new ->
        old == new
    }

    private val _dalyShorts = MutableStateFlow<List<InstaList>>(emptyList())
    val dalyShorts = _dalyShorts.asStateFlow().distinctUntilChanged { old, new ->
        old == new
    }

    private val _topProducts = MutableStateFlow<HomeTopProductCard?>(null)
    val topProducts = _topProducts.asStateFlow().distinctUntilChanged { old, new ->
        old == new
    }


    init {
        lifecycle.doOnCreate {
            getTopConsultant()
            getSlider()
            getTopCategory()

            coroutineScope.launch {
                getTopHomeCard()
                getPosts()
                getShorts()
            }
        }

        lifecycle.doOnStart {
            if (isUserLogin) {
                fetchWalletBalance()
            }
        }

        lifecycle.doOnDestroy {
            coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
        }
    }

    private fun fetchWalletBalance() {
        coroutineScope.launch {
            repository.getWalletBalance().collect {
                _walletState.emit(it)
            }
        }
    }

    private fun getSlider() {
        coroutineScope.launch {
            repository.fetchSliders().collect {
                _slidersData.emit(it)
            }
        }
    }

    private fun getTopCategory() {
        coroutineScope.launch {
            repository.fetchHomeCategory().collect {
                _topCategory.emit(it)
            }
        }
    }

    private fun getTopConsultant() {
        coroutineScope.launch {
            repository.fetchTopConsultant().collect {
                _topConsultant.emit(it)
            }
        }
    }

//    private suspend fun getLiveSession() {
//        repository.fetchLiveSession().collect {
//            _liveSessionState.emit(it)
//        }
//    }

    private suspend fun getPosts() {
        repository.fetchPosts().collect {
            _postState.emit(it)
        }
    }

    private suspend fun getShorts() {
        repository.getShorts().collect {
            _dalyShorts.emit(it)
        }
    }

    private suspend fun getTopHomeCard() {
        repository.getTopProducts().collect {
            _topProducts.emit(it)
        }
    }

    private fun fetchData() {
        coroutineScope.launch {
            try {

//                val sliderResult = async { api.requestHomeScreenSlider() }
//                val categoryResult = async { api.requestHomeScreenCategory() }
//                val topConsultantResult = async { api.requestHomeScreenTopConsultant() }
//                val liveSessionResult = async { api.requestHomeScreenUpcomingLiveSessions() }
//                val getTopPost = async { api.requestGetAllPost(request = PostRequest("1")) }
//                val getRequestGetTodayShorts = async { api.requestGetTodayShorts() }

//                _state.update {
//                    it.copy(
//                        sliderData = sliderResult.await().data?.data?.slider ?: emptyList(),
//                        categories = categoryResult.await().data?.categories?.filter { filtered ->
//                            filtered.name in listOf(
//                                "Astrologer",
//                                "Mental Wellness Therapist",
//                                "Scientific Astrology"
//                            )
//                        } ?: emptyList(),
//                        consultants = topConsultantResult.await().data?.data?.let { check ->
//                            TopConsultantData(
//                                check.topConsultants ?: emptyList(),
//                                check.freeCallAvailability == 1
//                            )
//                        },
//                        liveSessions = liveSessionResult.await().data?.data?.sliderList
//                            ?: emptyList(),
//                        shorts = getRequestGetTodayShorts.await().data ?: emptyList(),
//                        posts = getTopPost.await().data?.let { filt ->
//                            filt.data.filter { it.postContentType == "image" && !it.postDescription.isNullOrBlank() } // Filter criteria
//                                .take(5) // Take only the top 5 items after filtering
//
//                        } ?: emptyList(),
//                        isLoading = false
//                    )
//                }


//                 slider Result
//                if (sliderResult.await() is Resources.Success<*>) {
//                    _slidersData.value = sliderResult.await().data?.data?.slider
//                }

//                 Category Result
//                if (categoryResult.await() is Resources.Success<*>) {
//                    val category = categoryResult.await().data?.categories?.filter {
//                        it.name in listOf(
//                            "Astrologer",
//                            "Mental Wellness Therapist",
//                            "Scientific Astrology"
//                        )
//                    }
//
//                    category?.let {
//                        _topCategory.value = category
//                    }
//                }

//                Top Consultant Result
//                if (topConsultantResult.await() is Resources.Success<*>) {
//                    val result = topConsultantResult.await().data?.data
//
//                    result?.topConsultants?.let {
//                        _topConsultant.value = TopConsultantData(it, result.freeCallAvailability ==1)
//                    }
//                }

//                 Live Session Result
//                if (liveSessionResult.await() is Resources.Success<*>) {
//                    val result = liveSessionResult.await()
//                    result.data?.let {
//                        _liveSessionState.value = it.data?.sliderList
//                    }
//                }

//                 Dail Reels
//                if(getRequestGetTodayShorts.await() is Resources.Success<*>){
//                    getRequestGetTodayShorts.await().data?.let {
//                        _dalyShorts.value = it
//                    }
//                }

//                 Top Posts
//                if (getTopPost.await() is Resources.Success<*>) {
//                    getTopPost.await().data?.let { response ->
//                        val topFivePosts = response.data
//                            .filter { it.postContentType == "image" && !it.postDescription.isNullOrBlank() } // Filter criteria
//                            .take(5) // Take only the top 5 items after filtering
//
//                        _postState.value = topFivePosts
//                    }
//                }

            } catch (e: Exception) {
//                _state.update { it.copy(isLoading = false, error = e.message) }
                // Ensure that loading is marked complete even if there is an error
//                _isLoadingComplete.value = false
            }
        }
    }

    fun event(event: HomePageEvent) {
        onHomePageEvent.invoke(event)
    }
}
