package tta.destinigo.talktoastro.feature_home.presentation.home_tab

import androidx.compose.animation.AnimatedContent
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.FloatingActionButton
import androidx.compose.material3.Icon
import androidx.compose.material3.Scaffold
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.feature_home.presentation.home_tab.home_screen_widget.SearchFieldPreview
import tta.destinigo.talktoastro.feature_home.presentation.ecom_list.HomeTopProductSegment
import tta.destinigo.talktoastro.feature_home.presentation.home_tab.footer.Footer
import tta.destinigo.talktoastro.feature_home.presentation.home_tab.home_screen_widget.ConsultantListItemUI
import tta.destinigo.talktoastro.feature_home.presentation.home_tab.home_screen_widget.PostCardList
import tta.destinigo.talktoastro.feature_home.presentation.home_tab.home_screen_widget.ToolBar
import tta.destinigo.talktoastro.feature_home.presentation.home_tab.home_screen_widget.TopCategoryList
import tta.destinigo.talktoastro.feature_home.presentation.home_tab.home_screen_widget.VideoCardList
import tta.destinigo.talktoastro.feature_home.presentation.home_tab.home_screen_widget.WelcomeSlide
import tta.destinigo.talktoastro.feature_home.zoho_sales_iq.injectZohoSales
import tta.destinigo.talktoastro.feature_home.zoho_sales_iq.onClickSupport
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.baseline_support_agent_24
import tta.destinigo.talktoastro.shared.config.WINYWAY_SHOP_LINK
import tta.destinigo.talktoastro.shared.platform.isJS
import tta.destinigo.talktoastro.shared.platform.openUrlInBrowser
import tta.destinigo.talktoastro.shared.platform.showToast
import tta.destinigo.talktoastro.shared.theme.Scale
import tta.destinigo.talktoastro.shared.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import tta.destinigo.talktoastro.shared.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import tta.destinigo.talktoastro.shared.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import tta.destinigo.talktoastro.shared.theme.background_color
import tta.destinigo.talktoastro.shared.theme.primaryDark


@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun HomeScreenTab(
    component: HomeScreenTabComponent
) {

    val sliderState by component.slidersData.collectAsState()
    val consultantState by component.topConsultant.collectAsState(null)
    val topHomeProduct by component.topProducts.collectAsState(null)
    val shortsState by component.dalyShorts.collectAsState(emptyList())
    val postState by component.postState.collectAsState(emptyList())

    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_COMPACT
    }

    LaunchedEffect(Unit) {
        injectZohoSales()
    }


    // Track scroll state
    val scrollState = rememberScrollState()
    // Detect scroll direction and hide TopBar when scrolling down

    Scaffold(
        containerColor = background_color,
        topBar = {

            ToolBar(
                onClickLogin = { component.event(HomePageEvent.OnClickLogin) },
                onClickProfile = { component.event(HomePageEvent.OnClickProfileIcon) },
                onClickWallet = { component.event(HomePageEvent.OnClickWalletIcon) },
                component = component
            )

        }, floatingActionButton = {
            if (!isJS()) {
                FloatingActionButton(
                    onClick = { onClickSupport() },
                    containerColor = primaryDark,
                    shape = CircleShape
                ) {
                    Icon(
                        painter = painterResource(Res.drawable.baseline_support_agent_24),
                        contentDescription = "Support",
                        tint = Color.White
                    )
                }
            }
        }) { innerPadding ->

        Column(
            modifier = Modifier.fillMaxSize().padding(innerPadding).verticalScroll(scrollState)

        ) {

            if (windowSizeClass == WindowWidthSizeClass.Compact){

                SearchFieldPreview(elevation = Scale.ELEVATION_SMALL, modifier = Modifier.fillMaxWidth(), onClick = {
                    component.event(HomePageEvent.OnSearchClick(""))
                })

                Spacer(modifier = Modifier.height(8.dp))
            }else{
                Spacer(modifier = Modifier.height(8.dp))
            }

            AnimatedContent(targetState = sliderState) { state ->
                WelcomeSlide(state, modifier = widthModifier)
            }

            AnimatedContent(
                component
            ) { state ->
                TopCategoryList(
                    onCategoryClick ={
                        component.event(HomePageEvent.OnClickCategory(it))
                    },
                    component = state,
                    modifier = widthModifier,
                    windowWidthSizeClass = windowSizeClass
                )
            }

            AnimatedContent(
                targetState = consultantState
            ) { state ->
                ConsultantListItemUI(
                    modifier = widthModifier,
                    context = component,
                    component = state,
                    onLoginClick = {
                        component.event(HomePageEvent.OnClickLogin)
                    },
                    onChatConsultation = { chatData, duration, category ->
                        component.event(
                            HomePageEvent.OnChatConsultationStart(
                                consultantId = chatData.id!!,
                                consultantName = chatData.full_name!!,
                                consultantImage = chatData.profile_image!!,
                                consultantSkills = chatData.skills!!,
                                chatDuration = duration,
                                category = category
                            )
                        )
                    },
                    onItemClick = { consultant ->
                        val category_id: Int? = consultant.id
                        if (category_id != null) {
                            component.event(
                                HomePageEvent.OnClickTopConsultant(
                                    category_id
                                )
                            )
                        }
                    },
                    onCallConsultation = { callData ->
                        component.event(
                            HomePageEvent.OnCallConsultationStart(
                                consultantId = callData.id!!,
                                consultantName = callData.full_name!!,
                                consultantImage = callData.profile_image!!,
                                consultantSkills = callData.skills!!
                            )
                        )
                    },
                    onRechargeRequired = { rechargeData ->
                        component.event(
                            HomePageEvent.OnRequiredRecharge(
                                rechargeData
                            )
                        )
                    },
                    onVoIPClick = { duration, expertInfo -> },
                    onNavigateCallWaitingPage = { CommonExpertInfo, onClose -> },
                    onLowBalance = {
                        component.event(HomePageEvent.OnClickWalletIcon)
                    },
                    onSeeAllClick = { category ->
                        //Logger.d { "Subcategory $category" }
                        component.event(HomePageEvent.OnClickCategory(category))
                    }
                )
            }

            topHomeProduct?.let {
                AnimatedContent(it) { state ->
                    HomeTopProductSegment(
                        widthModifier,
                        state,
                        onClickProduct = { productUrl ->
                            openUrlInBrowser(productUrl)
                        },
                        onClickSeeAll = {
                            openUrlInBrowser(WINYWAY_SHOP_LINK)
                        }
                    )
                }
            }


            AnimatedContent(targetState = shortsState) { state ->
                VideoCardList(
                    modifier = widthModifier,
                    component = state,
                    onSeeAllClick = {
                        showToast("Coming Soon")
                    }
                )
            }

            Spacer(modifier = Modifier.height(10.dp))

            AnimatedContent(targetState = postState) { state ->
                PostCardList(
                    modifier = widthModifier,
                    component = state,
                    onSeeAllClick = {
                        component.event(HomePageEvent.OnClickPostSeeAll)
                    },
                    onReadPostClick = { post ->
                        component.event(HomePageEvent.OnClickReadPost(post.tableId.toString()))
                    }
                )
            }

            Spacer(modifier = Modifier.height(10.dp))

            if (isJS()) {
                Footer(component)
            }

        }
    }
}
